import { DirectionActions } from "../../types";
import { CanvasTranslateHandler } from "./types";

const downTranslateHandler: CanvasTranslateHandler = {
  isApplicable: (action: DirectionActions) => {
    return action === DirectionActions.DOWN;
  },
  apply: (currentPosition, canvasSize, imageSize, scale) => {
    const canvasHeight = canvasSize.height / scale;
    const imageHeight = imageSize.height;

    let maxY = canvasHeight - imageHeight;
    let newY = currentPosition.y - canvasHeight * (0.1 * scale);

    if (canvasHeight < imageHeight) maxY = imageHeight - canvasHeight;

    if (Math.abs(newY) > maxY / 2) {
      newY = currentPosition.y;
    }
    return { x: currentPosition.x, y: newY };
  },
};

export default downTranslateHandler;
