import styled, { keyframes } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 45px;
  z-index: 99;
  opacity: 0;
  overflow: hidden;
  height: 460px;
  animation: ${() => fadeInFromTop} 0.2s forwards;
  width: 440px;
`;
const SettingsContainer = styled.div`
  display: flex;
  outline: none;
  padding: 10px;
  min-width: 400px;
  min-height: 300px;
  max-height: 400px;
  overflow-y: auto;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  height: 20px;
  align-itens: flex-start;
  flex-direction: row;
  padding: 10px;
`;

const Title = styled.h3`
  padding-top: 5px;
  margin: 0;
  color: #20324a;
  flex: 1;
`;
const SubTitle = styled.h3`
  padding-top: 5px;
  margin: 0;
  color: #20324a;
  flex: 1;
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0;
  margin-right: 20px;
`;
const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  h3 {
    padding-bottom: 10px;
  }
`;

const OptionLabel = styled.h5`
  padding-top: 5px;
  flex: 1;
  margin: 0;
  color: #20324a;
  margin-right: 100px;
`;

const CheckButton = styled.div`
  border: 1px solid white;
  margin-top: 5px;
  display: flex;
  align-content: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  border: 1px solid #667486;
  cursor: pointer;
`;

const fadeInFromTop = keyframes`
0% {
    transform: translate(-50%, -50%);
    opacity: 0;
}
100% {
    transform: translate(-50%, 0);
    opacity: 1;
}
`;

export {
  SettingsContainer,
  Container,
  Title,
  SubTitle,
  Header,
  OptionContainer,
  OptionLabel,
  CheckButton,
  SectionContainer,
};
