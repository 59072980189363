import { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import settingsSelectors from "../store/Settings/selectors";
import { DirectionActions, UserInteraction, ZoomActions } from "../types";

type MousePosition = {
  x: number;
  y: number;
};

const useMouseControls = () => {
  const invertXMouseAxis = useSelector(settingsSelectors.getInvertXMouseAxis);
  const invertYMouseAxis = useSelector(settingsSelectors.getInvertYMouseAxis);
  const [currentChangeAction, setCurrentChangeAction] =
    useState<UserInteraction>();
  const [isMouseDown, setIsMouseDown] = useState(false);
  const mouseDownPositionRef = useRef<MousePosition>();
  const initialMousePositionRef = useRef<MousePosition>();
  const currentMousePositionRef = useRef<MousePosition>();
  const momentumRef = useRef<any>();
  const deltaXRef = useRef<number>(0);
  const deltaYRef = useRef<number>(0);
  const speedXRef = useRef<number>(0);
  const speedYRef = useRef<number>(0);

  const mouseSpeedTimeoutRef = useRef<any>(null);
  const cancelMomentumTracking = () => {
    cancelAnimationFrame(momentumRef.current);
  };

  const momentumLoop = () => {
    const directionAction = DirectionActions.CUSTOM;
    let deltaX = deltaXRef.current;
    let deltaY = deltaYRef.current;
    const slowDownFactorX = 0.7;
    const slowDownFactorY = 0.5;
    deltaXRef.current = deltaXRef.current * slowDownFactorX;
    deltaYRef.current = deltaYRef.current * slowDownFactorY;
    speedXRef.current *= slowDownFactorX;
    speedYRef.current *= slowDownFactorY;
    if (
      Math.abs(speedXRef.current) > 0.65 ||
      Math.abs(speedYRef.current) > 0.65
    ) {
      momentumRef.current = requestAnimationFrame(momentumLoop);
    }
    if (Math.abs(deltaXRef.current) <= 0.5) deltaX = 0;
    if (Math.abs(deltaYRef.current) <= 0.5) deltaY = 0;
    if (deltaX === 0 && deltaY === 0) {
      cancelMomentumTracking();
      return;
    }
    setCurrentChangeAction({
      directionAction,
      deltaX,
      deltaY,
    });
  };

  const beginMomentumTracking = () => {
    cancelMomentumTracking();
    momentumRef.current = requestAnimationFrame(momentumLoop);
  };

  const onMouseReleaseAnimation = () => {
    beginMomentumTracking();
  };

  const getMouseSpeed = () => {
    if (!initialMousePositionRef.current || !currentMousePositionRef.current)
      return;
    if (mouseSpeedTimeoutRef.current === null) {
      mouseSpeedTimeoutRef.current = Date.now();
      return;
    }
    const { x: x0, y: y0 } = initialMousePositionRef.current;
    const { x, y } = currentMousePositionRef.current;
    const now = Date.now();
    const dt = now - mouseSpeedTimeoutRef.current;
    const dx = x - x0;
    const dy = y - y0;
    speedXRef.current = Math.round((dx / dt) * 100);
    speedYRef.current = Math.round((dy / dt) * 100);

    mouseSpeedTimeoutRef.current = now;
  };

  const getMouseAction = useCallback(
    (shiftKeyPressed?: boolean) => {
      setCurrentChangeAction(undefined);
      if (!initialMousePositionRef.current) return;
      if (!currentMousePositionRef.current) return;
      const { x: x0, y: y0 } = initialMousePositionRef.current;
      const { x, y } = currentMousePositionRef.current;
      let directionAction = undefined;
      const offsetX = window.innerWidth * 0.02;
      const offsetY = window.innerHeight * 0.02;
      deltaXRef.current = 0;
      deltaYRef.current = 0;
      if (Math.abs(y - y0) > offsetY) {
        const dy = Math.abs(y - y0) / offsetY;
        if (invertYMouseAxis) {
          deltaYRef.current = y > y0 ? dy : -dy;
        } else {
          deltaYRef.current = y > y0 ? -dy : dy;
        }
        directionAction = DirectionActions.CUSTOM;
      }

      if (Math.abs(x - x0) > offsetX) {
        const dx = Math.abs(x - x0) / offsetX;
        if (invertXMouseAxis) {
          deltaXRef.current = x > x0 ? dx : -dx;
        } else {
          deltaXRef.current = x > x0 ? -dx : dx;
        }
        directionAction = DirectionActions.CUSTOM;
      }
      if (!directionAction) return;
      if (shiftKeyPressed && deltaYRef.current !== 0) {
        directionAction =
          deltaYRef.current > 0
            ? DirectionActions.FORWARD
            : DirectionActions.BACK;
      }
      setCurrentChangeAction({
        directionAction,
        deltaX: deltaXRef.current,
        deltaY: deltaYRef.current,
      });

      getMouseSpeed();
      initialMousePositionRef.current = currentMousePositionRef.current;
    },
    [invertXMouseAxis, invertYMouseAxis]
  );

  const onMouseDown = (event: MouseEvent) => {
    cancelMomentumTracking();
    setIsMouseDown(true);
    const { x, y } = event;
    speedXRef.current = 0;
    speedYRef.current = 0;
    initialMousePositionRef.current = { x, y };
    mouseDownPositionRef.current = { x, y };
  };
  const onTouchStart = (event: TouchEvent) => {
    setIsMouseDown(true);

    const { clientX: x, clientY: y } = event.touches[0];
    initialMousePositionRef.current = { x, y };
  };
  const onMouseMove = (event: MouseEvent) => {
    const { shiftKey } = event;
    if (isMouseDown) {
      const { x, y } = event;
      currentMousePositionRef.current = { x, y };
      getMouseAction(shiftKey);
    }
  };
  const onTouchMove = (event: TouchEvent) => {
    if (isMouseDown) {
      const { clientX: x, clientY: y } = event.touches[0];
      currentMousePositionRef.current = { x, y };
      getMouseAction();
    }
  };
  const onMouseUp = (event: MouseEvent) => {
    const { x, y } = event;
    currentMousePositionRef.current = { x, y };
    onMouseReleaseAnimation();
    setIsMouseDown(false);
  };
  const onTouchEnd = (_: TouchEvent) => {
    setIsMouseDown(false);
  };
  const onMouseLeave = (_: MouseEvent) => {
    setIsMouseDown(false);
  };
  const onWheel = (event: WheelEvent) => {
    cancelMomentumTracking();
    if (event.deltaY < 0) {
      setCurrentChangeAction({
        zoomAction: ZoomActions.IN,
        deltaY: Math.abs(event.deltaY * 0.01),
      });
    } else {
      setCurrentChangeAction({
        zoomAction: ZoomActions.OUT,
        deltaY: Math.abs(event.deltaY * 0.01),
      });
    }
  };

  return {
    onMouseDown,
    onMouseLeave,
    onMouseMove,
    onMouseUp,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    onWheel,
    isMouseDown,
    currentChangeAction,
  };
};

export default useMouseControls;
