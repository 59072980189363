import { DirectionActions } from "../../types";
import { CanvasTranslateHandler } from "./types";

const customTranslateHandler: CanvasTranslateHandler = {
  isApplicable: (action: DirectionActions) => {
    return action === DirectionActions.CUSTOM;
  },
  apply: (
    currentPosition,
    canvasSize,
    imageSize,
    scale,
    deltaX = 0,
    deltaY = 0
  ) => {
    const canvasWidth = canvasSize.width / scale;
    const imageWidth = imageSize.width;
    const canvasHeight = canvasSize.height / scale;
    const imageHeight = imageSize.height;
    let maxY = canvasHeight - imageHeight;
    if (canvasHeight < imageHeight) maxY = imageHeight - canvasHeight;
    let maxX = canvasWidth - imageWidth;
    if (canvasWidth < imageWidth) maxX = imageWidth - canvasWidth;

    let newX = currentPosition.x + deltaX / (0.06 * scale);
    let newY = currentPosition.y + deltaY / (0.06 * scale);

    if (Math.abs(newX) > maxX / 2) {
      newX = currentPosition.x;
    } else if (newX - maxX / 2 >= 0) {
      newX = currentPosition.x;
    }

    if (Math.abs(newY) > maxY / 2) {
      newY = currentPosition.y;
    } else if (newY - maxY / 2 >= 0) {
      newY = currentPosition.y;
    }
    return { x: newX, y: newY };
  },
};

export default customTranslateHandler;
