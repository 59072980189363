import styled from "styled-components";


const Container = styled.div`
  display: flex;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  background-color: #20324a;
  flex-direction: row;
  border-radius: 6px;
  padding: 4px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 5px;
  z-index: 99;
`;

const SelectedModeContainer = styled.div`
  display: flex;
  outline: none;
  color: white;
`;

const SelectViewOptionContainer = styled.div`
  display: grid;
  width: 140px;
  overflow: hidden;
  grid-template-columns: 1fr auto;
  align-content: center;
  padding: 4px 8px;
  cursor: pointer;
  border-bottom: 1px solid #667486;
  &:hover {
    background-color: #667486;
    color: white;
  }
  &:last-of-type {
    border-bottom: none;
  }
`;

export { Container, SelectedModeContainer, SelectViewOptionContainer };
