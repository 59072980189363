import { SelectionMode } from "../../types";
import {
  CurrentPhotoDataAction,
  CurrentPhotoDataState,
  CurrentPhotoDataActionType,
  CurrentPhotoDataReducer,
  CurrentPhotoDataPayload,
} from "./types";

export const initialState: CurrentPhotoDataState = {
  currentPhotoIndex: 0,
  selectionMode: SelectionMode.VR2D,
};

const setCurrentPhotoData: CurrentPhotoDataReducer = (
  state: CurrentPhotoDataState,
  payload?: CurrentPhotoDataPayload
) => ({
  ...state,
  currentPhoto:
    payload && payload.currentPhoto ? payload.currentPhoto : state.currentPhoto,
});
const setSelectionMode: CurrentPhotoDataReducer = (
  state: CurrentPhotoDataState,
  payload?: CurrentPhotoDataPayload
) => ({
  ...state,
  selectionMode: payload?.selectionMode || state.selectionMode,
});

const clearState: CurrentPhotoDataReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<CurrentPhotoDataActionType, CurrentPhotoDataReducer>(
  [
    [CurrentPhotoDataActionType.SET_CURRENT_PHOTO_DATA, setCurrentPhotoData],
    [CurrentPhotoDataActionType.SET_SELECTION_MODE, setSelectionMode],
    [CurrentPhotoDataActionType.CLEAR, clearState],
  ]
);

const reducer = (
  state: CurrentPhotoDataState = initialState,
  action: CurrentPhotoDataAction
): CurrentPhotoDataState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
