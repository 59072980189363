import { DirectionActions } from "../../types";
import { CanvasTranslateHandler } from "./types";

const rightTranslateHandler: CanvasTranslateHandler = {
  isApplicable: (action: DirectionActions) => {
    return action === DirectionActions.RIGHT;
  },
  apply: (currentPosition, canvasSize, imageSize, scale) => {
    const canvasWidth = canvasSize.width / scale;
    const imageWidth = imageSize.width;
    let maxX = canvasWidth - imageWidth;
    let newX = currentPosition.x - canvasWidth * (0.1 * scale);

    if (canvasWidth < imageWidth) maxX = imageWidth - canvasWidth;

    if (Math.abs(newX) > maxX / 2) {
      newX = currentPosition.x;
    }
    return { x: newX, y: currentPosition.y };
  },
};

export default rightTranslateHandler;
