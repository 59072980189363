import { useCallback } from "react";
import { PhotoData } from "../types";

const useGeoLocationData = (currentImage?: PhotoData) => {
  const getCurrentYaw = useCallback(() => {
    if (!currentImage) return;
    return currentImage.yaw;
  }, [currentImage]);

  const getCurrentLatitude = useCallback(() => {
    if (!currentImage) return;
    const latitude = currentImage.latitude;
    return latitude.toFixed(6);
  }, [currentImage]);

  const getCurrentLongitude = useCallback(() => {
    if (!currentImage) return;
    const longitude = currentImage.longitude;
    return longitude.toFixed(6);
  }, [currentImage]);

  const getCurrentAbsoluteAltitude = useCallback(() => {
    if (!currentImage) return;
    return currentImage.absolute_altitude.toFixed(2);
  }, [currentImage]);

  const getCurrentRelativeAltitude = useCallback(() => {
    if (!currentImage) return;
    return currentImage.relative_altitude
      ? currentImage.relative_altitude.toFixed(2)
      : undefined;
  }, [currentImage]);

  return {
    currentYaw: getCurrentYaw(),
    currentLatitude: getCurrentLatitude(),
    currentLongitude: getCurrentLongitude(),
    currentAbsoluteAltitude: getCurrentAbsoluteAltitude(),
    currentRelativeAltitude: getCurrentRelativeAltitude(),
  };
};

export default useGeoLocationData;
