const LocalStorage = window.localStorage;
const SELECTED_PHOTO_COLOR_KEY = "SELECTED_PHOTO_COLOR";
const VR2D_PHOTO_COLOR_KEY = "VR2D_PHOTO_COLOR";
const OUTLIER_PHOTO_COLOR_KEY = "OUTLIER_PHOTO_COLOR";
const HEIGHT_CALC_GROUP_PHOTO_COLOR_KEY = "HEIGHT_CALC_GROUP_PHOTO_COLOR";
const INFORMED_TOWER_COLOR_KEY = "INFORMED_TOWER_COLOR";
const ESTIMATED_TOWER_COLOR_KEY = "ESTIMATED_TOWER_COLOR_KEY";
const INVERT_X_MOUSE_AXIS_KEY = "INVERT_X_MOUSE_AXIS";
const INVERT_Y_MOUSE_AXIS_KEY = "INVERT_Y_MOUSE_AXIS";
const SHOULD_DISPLAY_HEIGHT_CALC_GROUP_PHOTO_KEY =
  "SHOULD_DISPLAY_HEIGHT_CALC_GROUP_PHOTO";
const AUTO_CENTER_CAMERA_KEY = "AUTO_CENTER_CAMERA";

export const saveSelectedPhotoColor = async (color: string) =>
  LocalStorage.setItem(SELECTED_PHOTO_COLOR_KEY, color);

export const getSelectedPhotoColor = async () =>
  LocalStorage.getItem(SELECTED_PHOTO_COLOR_KEY);

export const saveVr2dPhotoColor = async (color: string) =>
  LocalStorage.setItem(VR2D_PHOTO_COLOR_KEY, color);

export const getVr2dPhotoColor = async () =>
  LocalStorage.getItem(VR2D_PHOTO_COLOR_KEY);

export const saveOutlierPhotoColor = async (color: string) =>
  LocalStorage.setItem(OUTLIER_PHOTO_COLOR_KEY, color);

export const getOutlierPhotoColor = async () =>
  LocalStorage.getItem(OUTLIER_PHOTO_COLOR_KEY);

export const saveHeightCalcGroupPhotoColor = async (color: string) =>
  LocalStorage.setItem(HEIGHT_CALC_GROUP_PHOTO_COLOR_KEY, color);

export const getHeightCalcGroupPhotoColor = async () =>
  LocalStorage.getItem(HEIGHT_CALC_GROUP_PHOTO_COLOR_KEY);

export const saveInformedTowerColor = async (color: string) =>
  LocalStorage.setItem(INFORMED_TOWER_COLOR_KEY, color);

export const getInformedTowerColor = async () =>
  LocalStorage.getItem(INFORMED_TOWER_COLOR_KEY);

export const saveEstimatedTowerColor = async (color: string) =>
  LocalStorage.setItem(ESTIMATED_TOWER_COLOR_KEY, color);

export const getEstimatedTowerColor = async () =>
  LocalStorage.getItem(ESTIMATED_TOWER_COLOR_KEY);

export const saveInvertXMouseAxis = async (invert: boolean) =>
  LocalStorage.setItem(INVERT_X_MOUSE_AXIS_KEY, invert.toString());

export const getInvertXMouseAxis = async () =>
  LocalStorage.getItem(INVERT_X_MOUSE_AXIS_KEY) === "true";

export const saveInvertYMouseAxis = async (invert: boolean) =>
  LocalStorage.setItem(INVERT_Y_MOUSE_AXIS_KEY, invert.toString());

export const getInvertYMouseAxis = async () =>
  LocalStorage.getItem(INVERT_Y_MOUSE_AXIS_KEY) === "true";

export const saveShouldDisplayHeightCalcGroupPhoto = async (
  shouldDisplayHeightCalcGroupPhoto: boolean
) =>
  LocalStorage.setItem(
    SHOULD_DISPLAY_HEIGHT_CALC_GROUP_PHOTO_KEY,
    shouldDisplayHeightCalcGroupPhoto.toString()
  );

export const getShouldDisplayHeightCalcGroupPhoto = async () =>
  LocalStorage.getItem(SHOULD_DISPLAY_HEIGHT_CALC_GROUP_PHOTO_KEY) === "true";

export const saveAutoCenterCamera = async (autoCenterCamera: boolean) =>
  LocalStorage.setItem(AUTO_CENTER_CAMERA_KEY, autoCenterCamera.toString());

export const getAutoCenterCamera = async () =>
  LocalStorage.getItem(AUTO_CENTER_CAMERA_KEY) === "true";
