import styled from "styled-components";
import { fadeInFromLeft, fadeInFromRight } from "../../styles/animations";
import { FloatButtonStyleProps } from "./types";

const Container = styled.div`
  position: absolute;
  z-index: 999;
  border-radius: 10px;
  overflow: hidden;
  background-color: #20324a;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  transition: opacity ease-in 0.2s;
`;

const AltText = styled.span`
  font-size: 12px;
  padding-left: 5px;
  white-space: nowrap;
  animation: 0.3s
    ${({ mirrored }: FloatButtonStyleProps) =>
      mirrored ? fadeInFromRight : fadeInFromLeft}
    ease-out forwards;
`;

export { Container, AltText };
