import { ApplicationState } from "../types";

const getCurrentPhotoDataState = (state: ApplicationState) =>
  state.CurrentPhotoData;
const getCurrentPhoto = (state: ApplicationState) =>
  getCurrentPhotoDataState(state).currentPhoto;
const getCurrentPhotoIndex = (state: ApplicationState) =>
  getCurrentPhotoDataState(state).currentPhotoIndex;
const getSelectionMode = (state: ApplicationState) =>
  getCurrentPhotoDataState(state).selectionMode;

const selectors = {
  getCurrentPhoto,
  getSelectionMode,
  getCurrentPhotoIndex,
};

export default selectors;
