import * as model from "./model";

export const saveSelectedPhotoColor = async (color: string) => {
  try {
    return await model.saveSelectedPhotoColor(color);
  } catch (e: any) {
    throw new Error(e);
  }
};

export const getSelectedPhotoColor = async () => {
  try {
    return await model.getSelectedPhotoColor();
  } catch (e: any) {
    throw new Error(e);
  }
};

export const saveVr2dPhotoColor = async (color: string) => {
  try {
    return await model.saveVr2dPhotoColor(color);
  } catch (e: any) {
    throw new Error(e);
  }
};

export const getVr2dPhotoColor = async () => {
  try {
    return await model.getVr2dPhotoColor();
  } catch (e: any) {
    throw new Error(e);
  }
};

export const saveOutlierPhotoColor = async (color: string) => {
  try {
    return await model.saveOutlierPhotoColor(color);
  } catch (e: any) {
    throw new Error(e);
  }
};

export const getOutlierPhotoColor = async () => {
  try {
    return await model.getOutlierPhotoColor();
  } catch (e: any) {
    throw new Error(e);
  }
};

export const saveHeightCalcGroupPhotoColor = async (color: string) => {
  try {
    return await model.saveHeightCalcGroupPhotoColor(color);
  } catch (e: any) {
    throw new Error(e);
  }
};

export const getHeightCalcGroupPhotoColor = async () => {
  try {
    return await model.getHeightCalcGroupPhotoColor();
  } catch (e: any) {
    throw new Error(e);
  }
};

export const saveInformedTowerColor = async (color: string) => {
  try {
    return await model.saveInformedTowerColor(color);
  } catch (e: any) {
    throw new Error(e);
  }
};

export const getInformedTowerColor = async () => {
  try {
    return await model.getInformedTowerColor();
  } catch (e: any) {
    throw new Error(e);
  }
};

export const saveEstimatedTowerColor = async (color: string) => {
  try {
    return await model.saveEstimatedTowerColor(color);
  } catch (e: any) {
    throw new Error(e);
  }
};

export const getEstimatedTowerColor = async () => {
  try {
    return await model.getEstimatedTowerColor();
  } catch (e: any) {
    throw new Error(e);
  }
};

export const saveInvertXMouseAxis = async (invert: boolean) => {
  try {
    return await model.saveInvertXMouseAxis(invert);
  } catch (e: any) {
    throw new Error(e);
  }
};

export const getInvertXMouseAxis = async () => {
  try {
    return await model.getInvertXMouseAxis();
  } catch (e: any) {
    throw new Error(e);
  }
};

export const saveInvertYMouseAxis = async (invert: boolean) => {
  try {
    return await model.saveInvertYMouseAxis(invert);
  } catch (e: any) {
    throw new Error(e);
  }
};

export const getInvertYMouseAxis = async () => {
  try {
    return await model.getInvertYMouseAxis();
  } catch (e: any) {
    throw new Error(e);
  }
};
export const saveShouldDisplayHeightCalcGroupPhoto = async (
  shouldDisplayHeightCalcGroupPhoto: boolean
) => {
  try {
    return await model.saveShouldDisplayHeightCalcGroupPhoto(
      shouldDisplayHeightCalcGroupPhoto
    );
  } catch (e: any) {
    throw new Error(e);
  }
};

export const getShouldDisplayHeightCalcGroupPhoto = async () => {
  try {
    return await model.getShouldDisplayHeightCalcGroupPhoto();
  } catch (e: any) {
    throw new Error(e);
  }
};

export const saveAutoCenterCamera = async (
  autoCenterCamera: boolean
) => {
  try {
    return await model.saveAutoCenterCamera(
      autoCenterCamera
    );
  } catch (e: any) {
    throw new Error(e);
  }
};

export const getAutoCenterCamera = async () => {
  try {
    return await model.getAutoCenterCamera();
  } catch (e: any) {
    throw new Error(e);
  }
};
