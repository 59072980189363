import styled from "styled-components";
import { SliderStyleProps } from "./types";

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
`;
const CheckboxContainer = styled.div`
  position: relative;
  width: 24px;
  height: 14px;
  margin-top: 2px;
`;
const Label = styled.label`
  display: flex;
  align-self: center;
`;

const LabelContent = styled.span`
  color: white;
  flex: 1;
  font-size: 12px;
  padding-right: 8px;
  user-select: none;
  white-space: nowrap;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  background-color: ${({ isChecked }: SliderStyleProps) =>
    isChecked ? "#162231" : "#aaa"};
  transition: 0.4s;
  border-radius: 34px;
  &:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 0;
    bottom: 0;
    background-color: ${({ isChecked, alt }: SliderStyleProps) =>
      alt ? (isChecked ? "white" : "#162231") : "white"};
    transition: 0.4s;
    border-radius: 50%;
    transform: ${({ isChecked }: SliderStyleProps) =>
      isChecked ? "translateX(10px)" : "none"};
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  &:checked {
    background-color: #162231;
  }
  &::focus {
    box-shadow: 0 0 1px #162231;
  }
`;

export { Container, Label, Slider, CheckBox, CheckboxContainer, LabelContent };
