import { Container } from "./styles";
import { ControlPanelSectionProps } from "./types";

const ControlPanelSection = ({
  isVisible = true,
  overFlowHidden = true,
  maxWidth,
  children,
}: ControlPanelSectionProps) => {
  return (
    <Container
      isVisible={isVisible}
      maxWidth={maxWidth}
      overFlowHidden={overFlowHidden}
    >
      {children}
    </Container>
  );
};
export default ControlPanelSection;
