import { ImagesMetaData } from "../../types";
import { ImagesDataAction, ImagesDataActionType } from "./types";

const actions = {
  setImagesMetaData: (data: ImagesMetaData): ImagesDataAction => ({
    payload: { data },
    type: ImagesDataActionType.SET_DATA,
  }),
  fetchImagesData: (projectId: string): ImagesDataAction => ({
    payload: { projectId },
    type: ImagesDataActionType.FETCH_IMAGES_DATA,
  }),
  setLoading: (loading: boolean): ImagesDataAction => ({
    payload: { loading },
    type: ImagesDataActionType.SET_LOADING,
  }),
  setError: (error: Error): ImagesDataAction => ({
    payload: { error },
    type: ImagesDataActionType.SET_ERROR,
  }),
  clearState: (): ImagesDataAction => ({
    type: ImagesDataActionType.CLEAR,
  }),
};

export default actions;
