import styled from "styled-components";

const ScaleText = styled.span`
  color: white;
  padding: 0 2px;
  align-self: center;
  font-size: 11px;
  user-select: none;
`;

export { ScaleText };
