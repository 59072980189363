import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CanvasImage, ImageData, LoadedImageData } from "../types";
import { loadImage, sleep } from "../utils";

import imagesDataSelectors from "../store/ImagesData/selectors";
import currentPhotoDataSelectors from "../store/CurrentPhotoData/selectors";

const MAX_PARALLEL_DOWNLOADS = 32;
const useImagesManagment = () => {
  const currentPhotoData = useSelector(
    currentPhotoDataSelectors.getCurrentPhoto
  );

  const photos = useSelector(imagesDataSelectors.getPhotos);
  const basePath = useSelector(imagesDataSelectors.getBasePath);
  const fullSizePath = useSelector(imagesDataSelectors.getFullSizePath);
  const photosDownloadOrderList = useSelector(
    imagesDataSelectors.getPhotosDownloadOrderList
  );

  const totalPhotos = photos.length;

  const [currentCanvasImage, setCurrentCanvasImage] = useState<CanvasImage>();
  const currentImageRef = useRef<ImageData>();
  const currentCanvasImageRef = useRef<CanvasImage>();
  const loadedImagesRef = useRef<LoadedImageData[]>([]);
  const loadedFullSizeImagesRef = useRef<LoadedImageData[]>([]);
  const currentfetchImagesAmountRef = useRef(1);
  const [currentLoadProgress, setCurrentLoadProgress] = useState(0);
  const initialLoadingRef = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const imageDownloadQueueRef = useRef<number[]>();
  const dynamicMaxParallelDownloadsRef = useRef(4);
  const currentParallelDownloadsRef = useRef(0);

  const getImageById = useCallback(
    (imageId: string): HTMLImageElement | undefined => {
      if (loadedImagesRef.current && loadedImagesRef.current.length > 0) {
        const loadedImageData = loadedImagesRef.current.find(
          (fetchImageData) => {
            return fetchImageData.loaded && fetchImageData.id === imageId;
          }
        );
        if (!loadedImageData) return;
        return loadedImageData.image;
      }
    },
    [loadedImagesRef]
  );
  const getFullSizeImageById = useCallback(
    (imageId: string): HTMLImageElement | undefined => {
      if (
        loadedFullSizeImagesRef.current &&
        loadedFullSizeImagesRef.current.length > 0
      ) {
        const loadedImageData = loadedFullSizeImagesRef.current.find(
          (fetchImageData) => {
            return fetchImageData.loaded && fetchImageData.id === imageId;
          }
        );
        if (!loadedImageData) return;
        return loadedImageData.image;
      }
    },
    [loadedFullSizeImagesRef]
  );

  const fetchImage = useCallback(
    async (
      imageData: ImageData,
      fullSize?: boolean
    ): Promise<LoadedImageData> => {
      const path = fullSize ? fullSizePath : basePath;
      const imageSrc = `${path}${imageData.path}`;
      const imageId = `${fullSize ? "full_size_" : "regular_size_"}${
        imageData.id
      }`;
      let loadedImageData: LoadedImageData;
      try {
        const image = await loadImage(imageSrc, imageId);
        loadedImageData = {
          id: imageData.id,
          loaded: true,
          image,
        };
      } catch (e) {
        loadedImageData = {
          id: imageData.id,
          loaded: false,
          image: undefined,
        };
      }
      return loadedImageData;
    },
    [basePath, fullSizePath]
  );

  const onImageLoad = useCallback(
    (loadedImageData: LoadedImageData) => {
      const loadedImages = loadedImagesRef.current.slice();
      loadedImages.push(loadedImageData);
      loadedImagesRef.current = loadedImages;
    },
    [loadedImagesRef]
  );

  const getFullSizeImageData = useCallback(
    (imageData) => {
      const image = getFullSizeImageById(imageData.id);
      if (image) return image;
    },
    [getFullSizeImageById]
  );

  const fetchFullSizeImage = useCallback(
    async (imageData: ImageData) => {
      const fullSizeImageData = await fetchImage(imageData, true);
      const loadedFullSizeImages = loadedFullSizeImagesRef.current.slice();
      loadedFullSizeImages.push(fullSizeImageData);
      loadedFullSizeImagesRef.current = loadedFullSizeImages;

      if (!currentImageRef.current) return;
      if (
        currentCanvasImageRef.current &&
        fullSizeImageData.id === currentImageRef.current.id
      ) {
        const newCanvasImage: CanvasImage = {
          image: currentCanvasImageRef.current.image,
          fullSizeImage: fullSizeImageData.image,
        };
        currentCanvasImageRef.current = newCanvasImage;
        setCurrentCanvasImage(currentCanvasImageRef.current);
      }
    },
    [fetchImage]
  );

  const getFullSizeImage = useCallback(() => {
    const imageData = currentImageRef.current;
    if (!imageData) return;
    const fullSizeImage = getFullSizeImageData(imageData);
    if (fullSizeImage) {
      const newCanvasImage: CanvasImage = {
        image: currentCanvasImageRef.current?.image,
        fullSizeImage: fullSizeImage,
      };
      currentCanvasImageRef.current = newCanvasImage;
      return;
    }
    fetchFullSizeImage(imageData);
  }, [fetchFullSizeImage, getFullSizeImageData, currentImageRef]);

  const loadRegularSizeImageCanvas = useCallback(
    async (imageData) => {
      const image = getImageById(imageData.id);
      if (image) {
        const loadedImageData: LoadedImageData = {
          image,
          id: imageData.id,
          loaded: true,
        };
        return loadedImageData;
      }
      setIsLoading(true);
      const loadedImageData = await fetchImage(imageData);
      onImageLoad(loadedImageData);
      setIsLoading(false);

      if (!loadedImageData.loaded) return;
      return loadedImageData;
    },
    [getImageById, fetchImage, onImageLoad]
  );

  const handleCurrentImageChange = useCallback(async () => {
    if (!currentImageRef.current) return;
    const imageData = currentImageRef.current;
    if (!imageData || !imageData.path) return;
    const regularSizeImage = await loadRegularSizeImageCanvas(imageData);
    const fullSizeImage = getFullSizeImageData(imageData);
    if (
      regularSizeImage &&
      regularSizeImage.id === currentImageRef.current.id
    ) {
      currentCanvasImageRef.current = {
        image: regularSizeImage.image,
        fullSizeImage: fullSizeImage,
      };
    }
    setCurrentCanvasImage(currentCanvasImageRef.current);
  }, [loadRegularSizeImageCanvas, getFullSizeImageData, currentCanvasImageRef]);

  const updateParallelDownloadParams = useCallback(() => {
    currentParallelDownloadsRef.current--;
    if (
      currentfetchImagesAmountRef.current >=
        dynamicMaxParallelDownloadsRef.current &&
      dynamicMaxParallelDownloadsRef.current < MAX_PARALLEL_DOWNLOADS
    ) {
      const newMaxDynamicParallelDownloads =
        dynamicMaxParallelDownloadsRef.current * 2;
      dynamicMaxParallelDownloadsRef.current = Math.min(
        newMaxDynamicParallelDownloads,
        MAX_PARALLEL_DOWNLOADS
      );
    }
  }, []);

  const fetchImageAsync = useCallback(
    async (imageData: ImageData) => {
      const loadedImageData = await fetchImage(imageData);
      currentfetchImagesAmountRef.current++;
      if (
        currentfetchImagesAmountRef.current >= totalPhotos &&
        initialLoadingRef.current
      ) {
        initialLoadingRef.current = false;
      }
      const _currentLoadProgress = Math.floor(
        (currentfetchImagesAmountRef.current * 100) / totalPhotos
      );
      setCurrentLoadProgress(_currentLoadProgress);
      onImageLoad(loadedImageData);
      updateParallelDownloadParams();
    },
    [fetchImage, totalPhotos, onImageLoad, updateParallelDownloadParams]
  );

  const startDownloadImagesTicker = useCallback(async () => {
    while (imageDownloadQueueRef.current!.length > 0) {
      if (
        currentParallelDownloadsRef.current <
        dynamicMaxParallelDownloadsRef.current
      ) {
        let imageDownloadQueue = imageDownloadQueueRef.current!.slice();
        let imageIndex = imageDownloadQueue.shift();
        imageDownloadQueueRef.current = imageDownloadQueue;
        if (imageIndex === null || imageIndex === undefined) {
          continue;
        }
        const currentPhoto = photos.find(({ index }) => imageIndex === index);
        if (!currentPhoto) {
          continue;
        }
        const imageData = {
          path: currentPhoto.file,
          id: currentPhoto.index.toString(),
        };
        fetchImageAsync(imageData);
        currentParallelDownloadsRef.current++;
      } else {
        await sleep(10);
      }
    }
  }, [fetchImageAsync, photos]);

  const fetchAllImages = useCallback(async () => {
    if (initialLoadingRef.current) return;
    if (!photos || !photosDownloadOrderList) return;
    imageDownloadQueueRef.current = photosDownloadOrderList.map(
      ({ index }) => index
    );
    startDownloadImagesTicker();
  }, [photos, photosDownloadOrderList, startDownloadImagesTicker]);

  const openFullSizeImageInNewTab = useCallback(() => {
    if (!currentImageRef.current) return;
    const imageUrl = `${fullSizePath}${currentImageRef.current.path}`;
    window.open(imageUrl, "_blank");
  }, [fullSizePath]);

  useEffect(() => {
    if (!currentPhotoData) return;
    const currentImageData: ImageData | undefined = {
      path: currentPhotoData.file,
      id: currentPhotoData.index.toString(),
    };
    if (currentImageRef.current === currentImageData) return;

    currentImageRef.current = currentImageData;
    handleCurrentImageChange();
  }, [currentPhotoData, handleCurrentImageChange]);

  useEffect(() => {
    fetchAllImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    currentCanvasImage,
    fetchAllImages,
    getFullSizeImage,
    openFullSizeImageInNewTab,
    isLoading,
    currentLoadProgress,
  };
};

export default useImagesManagment;
