import {
  SettingsAction,
  SettingsActionType,
  SettingsPayload,
  SettingsReducer,
  SettingsState,
} from "./types";

export const initialState: SettingsState = {
  isVisible: false,
  selectedPhotoColor: "#ff1457",
  vr2dPhotoColor: "#1499ff",
  outlierPhotoColor: "#57ff14",
  heightCalcGroupPhotoColor: "#ff6614",
  informedTowerColor: "#ff5e00",
  estimatedTowerColor: "#0088ff",
  shouldDisplayHeightCalcGroupPhoto: false,
  invertXMouseAxis: true,
  invertYMouseAxis: true,
  autoCenterCamera: true,
};

const setVisible: SettingsReducer = (
  state: SettingsState,
  payload?: SettingsPayload
) => ({
  ...state,
  isVisible: payload ? !!payload?.isVisible : false,
});

const setSelectedPhotoColor: SettingsReducer = (
  state: SettingsState,
  payload?: SettingsPayload
) => ({
  ...state,
  selectedPhotoColor:
    payload && payload.selectedPhotoColor
      ? payload.selectedPhotoColor
      : state.selectedPhotoColor,
});

const setVr2dPhotoColor: SettingsReducer = (
  state: SettingsState,
  payload?: SettingsPayload
) => ({
  ...state,
  vr2dPhotoColor:
    payload && payload.vr2dPhotoColor
      ? payload.vr2dPhotoColor
      : state.vr2dPhotoColor,
});

const setOutlierPhotoColor: SettingsReducer = (
  state: SettingsState,
  payload?: SettingsPayload
) => ({
  ...state,
  outlierPhotoColor:
    payload && payload.outlierPhotoColor
      ? payload.outlierPhotoColor
      : state.outlierPhotoColor,
});

const setHeightCalcGroupPhotoColor: SettingsReducer = (
  state: SettingsState,
  payload?: SettingsPayload
) => ({
  ...state,
  heightCalcGroupPhotoColor:
    payload && payload.heightCalcGroupPhotoColor
      ? payload.heightCalcGroupPhotoColor
      : state.heightCalcGroupPhotoColor,
});
const setInformedTowerColor: SettingsReducer = (
  state: SettingsState,
  payload?: SettingsPayload
) => ({
  ...state,
  informedTowerColor:
    payload && payload.informedTowerColor
      ? payload.informedTowerColor
      : state.informedTowerColor,
});
const setEstimatedTowerColor: SettingsReducer = (
  state: SettingsState,
  payload?: SettingsPayload
) => ({
  ...state,
  estimatedTowerColor:
    payload && payload.estimatedTowerColor
      ? payload.estimatedTowerColor
      : state.estimatedTowerColor,
});

const setInvertXMouseAxis: SettingsReducer = (
  state: SettingsState,
  payload?: SettingsPayload
) => ({
  ...state,
  invertXMouseAxis: payload
    ? !!payload.invertXMouseAxis
    : state.invertXMouseAxis,
});

const setInvertYMouseAxis: SettingsReducer = (
  state: SettingsState,
  payload?: SettingsPayload
) => ({
  ...state,
  invertYMouseAxis: payload
    ? !!payload.invertYMouseAxis
    : state.invertYMouseAxis,
});

const setShouldDisplayHeightCalcGroupPhoto: SettingsReducer = (
  state: SettingsState,
  payload?: SettingsPayload
) => ({
  ...state,
  shouldDisplayHeightCalcGroupPhoto: payload
    ? !!payload.shouldDisplayHeightCalcGroupPhoto
    : state.shouldDisplayHeightCalcGroupPhoto,
});

const setAutoCenterCamera: SettingsReducer = (
  state: SettingsState,
  payload?: SettingsPayload
) => ({
  ...state,
  autoCenterCamera: payload
    ? !!payload.autoCenterCamera
    : state.autoCenterCamera,
});

const clearState: SettingsReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<SettingsActionType, SettingsReducer>([
  [SettingsActionType.SET_VISIBLE, setVisible],
  [SettingsActionType.SET_SELECTED_PHOTO_COLOR, setSelectedPhotoColor],
  [SettingsActionType.SET_VR2D_PHOTO_COLOR, setVr2dPhotoColor],
  [SettingsActionType.SET_OUTLIER_PHOTO_COLOR, setOutlierPhotoColor],
  [
    SettingsActionType.SET_HEIGHT_CALC_GROUP_PHOTO_COLOR,
    setHeightCalcGroupPhotoColor,
  ],
  [SettingsActionType.SET_INFORMED_TOWER_COLOR, setInformedTowerColor],
  [SettingsActionType.SET_ESTIMATED_TOWER_COLOR, setEstimatedTowerColor],
  [SettingsActionType.SET_INVERT_X_MOUSE_AXIS, setInvertXMouseAxis],
  [SettingsActionType.SET_INVERT_Y_MOUSE_AXIS, setInvertYMouseAxis],
  [
    SettingsActionType.SET_SHOULD_DISPLAY_HEIGHT_CALC_GROUP_PHOTO,
    setShouldDisplayHeightCalcGroupPhoto,
  ],
  [SettingsActionType.SET_AUTO_CENTER_CAMERA, setAutoCenterCamera],
  [SettingsActionType.CLEAR, clearState],
]);

const reducer = (
  state: SettingsState = initialState,
  action: SettingsAction
): SettingsState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
