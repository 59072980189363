export const resizeImageKeepAspectRatio = (
  srcWidth: number,
  srcHeight: number,
  maxWidth: number,
  maxHeight: number
) => {
  const ratio = Math.max(maxWidth / srcWidth, maxHeight / srcHeight);
  return ratio;
};

export const loadImage = async (
  imageSrc: string,
  imageId: string
): Promise<HTMLImageElement | undefined> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.id = imageId;
    image.src = imageSrc;

    image.onload = () => {
      resolve(image);
    };
    image.onerror = (error) => {
      console.error(error);
      reject(error);
    };
  });
};

export const requestFullScreen = async (element: any) => {
  if (element.requestFullscreen) {
    // W3C API
    return element.requestFullscreen();
  }
  if (element.mozRequestFullScreen) {
    // Mozilla current API
    return element.mozRequestFullScreen();
  }
  if (element.webkitRequestFullScreen) {
    // Webkit current API
    return element.webkitRequestFullScreen();
  }
};
export const exitFullScreen = async () => {
  if (document.exitFullscreen) {
    // W3C API
    return document.exitFullscreen();
  }
  if (document.mozCancelFullScreen) {
    // Mozilla current API
    return document.mozCancelFullScreen();
  }
  if (document.webkitExitFullscreen) {
    // Webkit current API
    return document.webkitExitFullscreen();
  }
};

export const isFullScreen = () => {
  return !!(document.fullscreenElement || document.webkitIsFullScreen);
};

export const easeInQuad = (
  elapsed: number,
  start: number,
  end: number,
  total: number
) => {
  return end * (elapsed /= total) * elapsed + start;
};

export const sleep = (ms: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => resolve(), ms);
  });
};

export const base64Decode = (str: string) => {
  return decodeURIComponent(escape(window.atob(str)));
};

export const hexToRgb = (hex: string) => {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return {
      R: (c >> 16) & 255,
      G: (c >> 8) & 255,
      B: c & 255,
    };
  }
  throw new Error("Bad Hex");
};

export const isChildNodeOf = (target: HTMLElement, parentNode: HTMLElement) => {
  let isChildOf = false;
  parentNode.childNodes.forEach((child) => {
    if (child === target) {
      isChildOf = true;
      return;
    }
  });
  return isChildOf;
};

export const isValueDefined = (value?: number): boolean => {
  if (value === undefined) return false;
  if (value === null) return false;
  return true;
};
