import { SideBySideIconProps } from "./types";
import { Container, LeftSide, RightSide } from "./styles";

const SideBySideIcon = ({
  outline,
  sideBySide,
  onlyLeftView,
  onlyRightView,
  fontSize = 16,
}: SideBySideIconProps) => {
  const baseClassName = "material-icons";
  return (
    <Container>
      <LeftSide halfView={sideBySide} collapsed={onlyRightView}>
        <i
          className={`${baseClassName}${outline ? "-outlined" : ""}`}
          style={{
            fontSize,
            userSelect: "none",
          }}
        >
          {"square"}
        </i>
      </LeftSide>
      <RightSide halfView={sideBySide} collapsed={onlyLeftView}>
        <i
          className={`${baseClassName}${outline ? "-outlined" : ""}`}
          style={{
            fontSize,
            userSelect: "none",
          }}
        >
          {"square"}
        </i>
      </RightSide>
    </Container>
  );
};

export default SideBySideIcon;
