import styled, { css } from "styled-components";
import { SideContainerProps } from "./types";

const Container = styled.div`
  display: flex;
  flex: 1;
`;

const LeftSide = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  max-width: 14px;
  ${({ halfView = true }: SideContainerProps) =>
    halfView &&
    css`
      max-width: 9px;
    `};
  ${({ collapsed }: SideContainerProps) =>
    collapsed &&
    css`
      max-width: 4px;
      margin-left: 1px;
    `};
`;
const RightSide = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  max-width: 14px;
  ${({ halfView = true }: SideContainerProps) =>
    halfView &&
    css`
      max-width: 9px;
    `};
  ${({ collapsed }: SideContainerProps) =>
    collapsed &&
    css`
      max-width: 4px;
    `};
`;

export { Container, LeftSide, RightSide };
