import styled from "styled-components";

const Container = styled.div`
  display: flex;
  user-select: none;
  -webkit-user-select: none; /* Safari fallback only */
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
`;

const ErrorText = styled.span``;

export { Container, ErrorText };
