import styled, { keyframes } from "styled-components";
import { DropdownMenuStyleProps } from "./types";

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 999;
  border-radius: 6px;
  overflow: hidden;
  color: #20324a;
  bottom: ${({ initialPosition }: DropdownMenuStyleProps) =>
    initialPosition.bottom};
  left: ${({ initialPosition }: DropdownMenuStyleProps) =>
    initialPosition.left};
  top: ${({ initialPosition }: DropdownMenuStyleProps) => initialPosition.top};
  right: ${({ initialPosition }: DropdownMenuStyleProps) =>
    initialPosition.right};
  background-color: white;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  opacity: 0;
  padding: 4px 0;
  height: 0;
  animation: 0.3s forwards
    ${({ isVisible, direction, height }: DropdownMenuStyleProps) =>
      isVisible
        ? direction === "FROM_TOP"
          ? fadeInFromTop(height)
          : fadeInFromBottom(height)
        : direction === "FROM_TOP"
        ? fadeOutToTop(height)
        : fadeOutToBottom(height)};
`;

export const fadeInFromTop = (height: string) => keyframes`
0% {
    transform: translateY(0);
    height: 0;
    opacity: 0;
}
100% {
    transform: translateY(100%);
    opacity: 1;
    height: ${height};
}
`;
export const fadeOutToTop = (height: string) => keyframes`
0% {
    transform: translateY(100%);
    height: ${height};
    opacity: 1;
}
100% {
    transform: translateY(0);
    opacity: 0;
    height: 0;
}
`;
export const fadeInFromBottom = (height: string) => keyframes`
0% {
    transform: translateY(0);
    height: 0;
    opacity: 0;
}
100% {
    transform: translateY(-100%);
    opacity: 1;
    height: ${height};
}
`;
export const fadeOutToBottom = (height: string) => keyframes`
0% {
    transform: translateY(-100%);
    height: ${height};
    opacity: 1;
}
100% {
    transform: translateY(0);
    opacity: 0;
    height: 0;
}
`;

export { Container };
