import { ColorPickerProps } from "./types";

const ColorPicker = ({ currentColor, onChange }: ColorPickerProps) => {
  const onInputChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (!target) return null;
    const newColor = target.value;
    onChange(newColor);
  };
  return (
    <input
      type={"color"}
      defaultValue={currentColor}
      onChange={(e) => onInputChange(e.nativeEvent)}
    />
  );
};

export default ColorPicker;
