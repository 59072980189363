import styled from "styled-components";
import { CompassProps } from "./types";

const ArrowImg = styled.img`
  display: flex;
  position: absolute;
  top: 12px;
  left: 12px;
  padding: 2px;
  transition: transform 0.3s ease-in-out;
  transform: rotate(${(props: CompassProps) => props.yaw}deg);
  flex: 1;
  width: 22px;
  height: 22px;
  filter: drop-shadow(0px 0px 3px rgb(255 255 255 / 0.7));
  outline: none;
  user-select: none;
`;
const CompassContainer = styled.div`
  display: flex;
  position: relative;
`;
const Container = styled.div`
  width: 50px;
  height: 50px;
  left: 0;
  bottom: 0;
  position: absolute;
`;
const CompassImg = styled.img`
  display: flex;
  flex: 1;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  filter: drop-shadow(0px 0px 3px rgb(255 255 255 / 0.7));
  outline: none;
  user-select: none;
`;

export { ArrowImg, CompassContainer, CompassImg, Container };
