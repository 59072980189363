import styled, { css } from "styled-components";
import { ButtonStyleProps } from "./types";

const ButtonStyled = styled.div`
  display: flex;
  align-items: center;
  outline: none;
  padding: 4px;
  cursor: pointer;
  background-color: ${(props: ButtonStyleProps) => props.backgroundColor};
  color: ${(props: ButtonStyleProps) => props.foregroundColor || "#f4f6f8"};
  ${(props: ButtonStyleProps) =>
    props.noHoverEffect
      ? ""
      : css`
          &:focus {
            outline: none;
            border: none;
          }
          &:hover {
            background-color: rgba(102, 116, 134, 0.3);
            color: white;
          }
        `};
  ${(props: ButtonStyleProps) =>
    props.active
      ? css`
          background-color: rgba(102, 116, 134, 0.3);
          color: white;
        `
      : ""};
`;

export { ButtonStyled };
