import { ErrorMessageProps } from "./types";
import { Container, ErrorText } from "./styles";

const ErrorMessage = ({ message }: ErrorMessageProps) => {
  return (
    <Container>
      <ErrorText>{message}</ErrorText>
    </Container>
  );
};

export default ErrorMessage;
