import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import useDrawCanvas from "../../hooks/useDrawCanvas";
import useImagesManagment from "../../hooks/useImagesManagment";
import { BlurredContainer } from "./styles";
import {
  CanvasImage,
  InteractionMode,
  UserInteraction,
  ZoomActions,
} from "../../types";
import Canvas from "../Canvas";
import LoadingBar from "../LoadingBar";
import ViewerContainer from "../ViewerContainer";
import { PhotoViewerProps } from "./types";
import currentPhotoDataActions from "../../store/CurrentPhotoData/actions";

const PhotoViewer = ({
  viewerSize,
  isVisible,
  transitionEnabled,
  currentChangeAction,
  interactionMode,
  getUserScaleRef,
  getCanvasRef,
  onVisibleChange,
  onInteractionModeChange,
}: PhotoViewerProps) => {
  const canvasContextRef = useRef<CanvasRenderingContext2D>();

  const dispatch = useDispatch();

  const {
    updateCanvas,
    zoomIn,
    zoomOut,
    clearZoom,
    translateCanvas,
    getUserScale,
    getOnScale,
  } = useDrawCanvas(canvasContextRef.current);

  const {
    currentCanvasImage,
    isLoading,
    currentLoadProgress,
    getFullSizeImage,
  } = useImagesManagment();

  const onZoomChange = () => {
    const newInteractionMode = getOnScale()
      ? InteractionMode.PAN
      : InteractionMode.DEFAULT;
    onInteractionModeChange(newInteractionMode);
  };

  const updatePosition = useCallback(
    (userInteraction: UserInteraction) => {
      dispatch(currentPhotoDataActions.updateCurrentPhoto(userInteraction));
    },
    [dispatch]
  );

  const handleChangeDirectionAction = useCallback(
    (currentChangeAction: UserInteraction) => {
      if (interactionMode === InteractionMode.DEFAULT) {
        updatePosition(currentChangeAction);
      }
      if (interactionMode === InteractionMode.PAN) {
        translateCanvas(currentChangeAction);
      }
    },
    [interactionMode, translateCanvas, updatePosition]
  );

  const handleChangeZoomAction = useCallback(
    (currentChangeAction: UserInteraction) => {
      getFullSizeImage();
      if (currentChangeAction.zoomAction === ZoomActions.IN) {
        zoomIn(currentChangeAction.deltaY);
      }
      if (currentChangeAction.zoomAction === ZoomActions.OUT) {
        zoomOut(currentChangeAction.deltaY);
      }
      if (currentChangeAction.zoomAction === ZoomActions.CLEAR) {
        clearZoom();
      }
      onZoomChange();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [zoomIn, zoomOut, clearZoom, getFullSizeImage]
  );

  useEffect(() => {
    getUserScaleRef(getUserScale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserScale]);

  useEffect(() => {
    if (!currentChangeAction) return;
    if (currentChangeAction.directionAction) {
      return handleChangeDirectionAction(currentChangeAction);
    }
    if (currentChangeAction.zoomAction) {
      return handleChangeZoomAction(currentChangeAction);
    }
  }, [
    handleChangeDirectionAction,
    handleChangeZoomAction,
    currentChangeAction,
  ]);

  const getCanvasContext = useCallback(
    (canvasContext: CanvasRenderingContext2D) => {
      canvasContextRef.current = canvasContext;
    },
    [canvasContextRef]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderCanvas = (newCanvasImage?: CanvasImage) => {
    if (newCanvasImage) {
      updateCanvas(newCanvasImage);
    }
  };

  useEffect(() => {
    if (currentCanvasImage && viewerSize) {
      renderCanvas(currentCanvasImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCanvasImage, viewerSize]);

  useEffect(() => {
    onInteractionModeChange && onInteractionModeChange(interactionMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interactionMode]);

  return (
    <>
      <ViewerContainer
        title={"Photo Viewer"}
        mirrored
        transitionEnabled={transitionEnabled}
        isVisible={isVisible}
        onVisibleChange={onVisibleChange}
        viewerSize={{ ...viewerSize }}
      >
        {isLoading && <BlurredContainer />}
        <Canvas
          size={viewerSize}
          getCanvasRef={getCanvasRef}
          getCanvasContext={getCanvasContext}
        />
      </ViewerContainer>
      {currentLoadProgress < 100 && (
        <LoadingBar progress={currentLoadProgress} />
      )}
    </>
  );
};

export default PhotoViewer;
