import { Container } from "./styles";
import { ToolbarContainerProps } from "./types";
const ToolbarContainer = ({
  children,
  position,
  active,
}: ToolbarContainerProps) => {
  return (
    <Container active={active} position={position}>
      {children}
    </Container>
  );
};

export default ToolbarContainer;
