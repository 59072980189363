

import styled from "styled-components";

const BlurredContainer = styled.div`
  position: absolute;
  background: rgba(180, 180, 180, 0.1);
  backdrop-filter: blur(6px);
  inset: 0;
`;

export { BlurredContainer };

