import { DirectionActions } from "../../types";
import { CanvasTranslateHandler } from "./types";

const upTranslateHandler: CanvasTranslateHandler = {
  isApplicable: (action: DirectionActions) => {
    return action === DirectionActions.UP;
  },
  apply: (currentPosition, canvasSize, imageSize, scale) => {
    const canvasHeight = canvasSize.height / scale;
    const imageHeight = imageSize.height;

    let maxY = canvasHeight - imageHeight;
    let newY = currentPosition.y + canvasHeight * (0.1 * scale);

    if (canvasHeight < imageHeight) maxY = imageHeight - canvasHeight;

    if (newY - maxY / 2 >= 0) {
      newY = currentPosition.y;
    }
    return { x: currentPosition.x, y: newY };
  },
};

export default upTranslateHandler;
