import { useEffect } from "react";
import { Provider } from "react-redux";
import InitialLoader from "./components/InitialLoader";
import Home from "./Pages/Home";
import Store from "./store";

function App() {
  useEffect(() => {
    return () => {
      window.stop();
    };
  }, []);
  return (
    <Provider store={Store}>
      <InitialLoader>
        <Home />
      </InitialLoader>
    </Provider>
  );
}

export default App;
