import styled from "styled-components";
import { LoadingBarStyleProps } from "./types";

const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${(props: LoadingBarStyleProps) => props.backgroundColor};
  display: grid;
  cursor: pointer;
  z-index: 100;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
`;
const Progress = styled.div`
  background: ${(props: LoadingBarStyleProps) => props.progressColor};
  border: 1px solid ${(props: LoadingBarStyleProps) => props.progressColor};
  padding: 0 5px;
  width: calc(100vw * ${(props: LoadingBarStyleProps) => props.progress});
  display: flex;
  padding: 2px 0;
  justify-content: center;
  font-size: 10px;
`;

export { Container, Progress };
