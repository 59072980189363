import styled from "styled-components";

const CanvasStyled = styled.canvas`
  background-color: black;
  outline: none;
  .focus: {
    outline: none;
    border: none;
  }
`;

export { CanvasStyled };
