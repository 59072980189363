import { IconProps } from "./types";
import { Container } from "./styles";

const Icon = ({ iconSrc, outline, inverted, fontSize = 16, color }: IconProps) => {
  const baseClassName = "material-icons";
  return (
    <Container>
      <i
        className={`${baseClassName}${outline ? "-outlined" : ""}`}
        style={{
          fontSize,
          color,
          userSelect: "none",
          transform: `${inverted ? "rotateX(180deg)" : "rotateX(0)"}`,
        }}
      >
        {iconSrc}
      </i>
    </Container>
  );
};

export default Icon;
