import { useEffect, useRef } from "react";
import { CanvasProps } from "./types";
import { CanvasStyled } from "./styles";

const Canvas = ({ size, getCanvasRef, getCanvasContext }: CanvasProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    if (getCanvasRef) getCanvasRef(canvas);
    const context = canvas.getContext("2d");
    if (!context) return;
    if (getCanvasContext) getCanvasContext(context);
  }, [getCanvasRef, getCanvasContext]);

  return (
    <CanvasStyled
      width={size.width}
      height={size.height}
      tabIndex={0}
      ref={canvasRef}
    />
  );
};

export default Canvas;
