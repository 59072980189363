import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Container, SplitViewContainer } from "./styles";
import OrbitViewer from "../../components/OrbitViewer";
import PhotoViewer from "../../components/PhotoViewer";
import currentPhotoDataActions from "../../store/CurrentPhotoData/actions";
import Toolbar from "../../components/Toolbar";
import SettingsModal from "../../components/SettingsModal";
import CurrentPhotoData from "../../components/CurrentPhotoData";
import useUserInteraction from "../../hooks/useUserInteraction";
import { InteractionMode } from "../../types";
import useVisibleViews from "../../hooks/useVisibleViews";
import useOrbitViewerControls from "../../hooks/useOrbitViewerControls";
import ViewSettings from "../../components/ViewSettings";

const Home = () => {
  const [interactionMode, setInteractionMode] = useState(
    InteractionMode.DEFAULT
  );

  const {
    currentChangeAction,
    userScale,
    setCanvasElement,
    onDirectionButtonClick,
    onZoomButtonClick,
    getUserScaleRef,
  } = useUserInteraction(interactionMode);

  const {
    orbitPhotoVisibleState,
    towerDisplayVisibleState,
    recenterCamera,
    getRecenterCameraRef,
    onInVRPhotosVisibleToggleChange,
    onOutlierPhotosVisibleToggleChange,
    onHeightCalcGroupVisibleToggleChange,
    onInformedTowerVisibleToggleChange,
    onEstimatedTowerVisibleToggleChange,
  } = useOrbitViewerControls();

  const {
    viewersVisibleState,
    isOrbitViewFloating,
    splitScreenViewerSizes,
    viewersTransitionEnable,
    onPhotoViewerVisibleChange,
    onOrbitViewerVisibleChange,
    onOrbitViewerFloatingChange,
    onViewerVisibilityStateChange,
  } = useVisibleViews();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(currentPhotoDataActions.getInitialPhotoData());
  }, [dispatch]);

  return (
    <Container>
      <ViewSettings
        onViewerVisibilityStateChange={onViewerVisibilityStateChange}
        viewersVisibleState={viewersVisibleState}
        isOrbitViewFloating={isOrbitViewFloating}
      />
      <Toolbar
        interactionMode={interactionMode}
        onDirectionButtonClick={onDirectionButtonClick}
        onZoomButtonClick={onZoomButtonClick}
        orbitPhotoVisibleState={orbitPhotoVisibleState}
        towerDisplayVisibleState={towerDisplayVisibleState}
        isOrbitViewFloating={isOrbitViewFloating}
        viewersVisibleState={viewersVisibleState}
        userScale={userScale}
        onInVRPhotosVisibleToggleChange={onInVRPhotosVisibleToggleChange}
        onOutlierPhotosVisibleToggleChange={onOutlierPhotosVisibleToggleChange}
        onHeightCalcGroupVisibleToggleChange={
          onHeightCalcGroupVisibleToggleChange
        }
        onRecenterCameraClick={recenterCamera}
        onInformedTowerVisibleToggleChange={onInformedTowerVisibleToggleChange}
        onEstimatedTowerVisibleToggleChange={onEstimatedTowerVisibleToggleChange}
      />
      <SettingsModal />
      <CurrentPhotoData />
      <SplitViewContainer>
        <OrbitViewer
          isFloatingWindow={isOrbitViewFloating}
          onVisibleChange={onOrbitViewerVisibleChange}
          onWindowFloatChange={onOrbitViewerFloatingChange}
          viewerSize={splitScreenViewerSizes.orbitViewer}
          isVisible={viewersVisibleState.orbitViewer}
          transitionEnabled={viewersTransitionEnable}
          recenterCameraRef={getRecenterCameraRef}
          orbitPhotoVisibleState={orbitPhotoVisibleState}
          towerDisplayVisibleState={towerDisplayVisibleState}
        />
        <PhotoViewer
          onVisibleChange={onPhotoViewerVisibleChange}
          viewerSize={splitScreenViewerSizes.photoViewer}
          isVisible={viewersVisibleState.photoViewer}
          transitionEnabled={viewersTransitionEnable}
          currentChangeAction={currentChangeAction}
          getCanvasRef={setCanvasElement}
          getUserScaleRef={getUserScaleRef}
          onInteractionModeChange={setInteractionMode}
          interactionMode={interactionMode}
        />
      </SplitViewContainer>
    </Container>
  );
};

export default Home;
