import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import settingsSelectors from "../../store/Settings/selectors";
import settingsActions from "../../store/Settings/actions";
import Button from "../Button";
import Icon from "../Icon";
import {
  Container,
  SettingsContainer,
  Title,
  Header,
  OptionContainer,
  OptionLabel,
  CheckButton,
  SubTitle,
  SectionContainer,
} from "./styles";
import ColorPicker from "../ColorPicker";

const SettingsModal = () => {
  const [headerStyle, setHeaderStyle] = useState({});
  const isVisible = useSelector(settingsSelectors.isVisible);
  const selectedPhotoColor = useSelector(
    settingsSelectors.getSelectedPhotoColor
  );
  const vr2dPhotoColor = useSelector(settingsSelectors.getVr2dPhotoColor);
  const outlierPhotoColor = useSelector(settingsSelectors.getOutlierPhotoColor);
  const heightCalcGroupPhotoColor = useSelector(
    settingsSelectors.getHeightCalcGroupPhotoColor
  );
  const informedTowerColor = useSelector(
    settingsSelectors.getInformedTowerColor
  );
  const estimatedTowerColor = useSelector(
    settingsSelectors.getEstimatedTowerColor
  );
  const invertMouseX = useSelector(settingsSelectors.getInvertXMouseAxis);
  const invertMouseY = useSelector(settingsSelectors.getInvertYMouseAxis);
  const shouldDisplayHeightCalcGroupPhotos = useSelector(
    settingsSelectors.shouldDisplayHeightCalcGroupPhotos
  );
  const autoCenterCamera = useSelector(settingsSelectors.getAutoCenterCamera);

  const dispatch = useDispatch();

  const onCloseButtonClick = useCallback(() => {
    dispatch(settingsActions.setVisible(false));
  }, [dispatch]);

  const onSelectPhotoColorChange = useCallback(
    (newColor: string) => {
      dispatch(settingsActions.saveSelectedPhotoColor(newColor));
    },
    [dispatch]
  );

  const onVr2dPhotoColorChange = useCallback(
    (newColor: string) => {
      dispatch(settingsActions.saveVr2dPhotoColor(newColor));
    },
    [dispatch]
  );

  const onOutlierPhotoColorChange = useCallback(
    (newColor: string) => {
      dispatch(settingsActions.saveOutlierPhotoColor(newColor));
    },
    [dispatch]
  );
  const onHeightCalcGroupPhotoColorChange = useCallback(
    (newColor: string) => {
      dispatch(settingsActions.saveHeightCalcGroupPhotoColor(newColor));
    },
    [dispatch]
  );

  const onInformedTowerColorChange = useCallback(
    (newColor: string) => {
      dispatch(settingsActions.saveInformedTowerColor(newColor));
    },
    [dispatch]
  );

  const onEstimatedTowerColorChange = useCallback(
    (newColor: string) => {
      dispatch(settingsActions.saveEstimatedTowerColor(newColor));
    },
    [dispatch]
  );

  const onInvertMouseXCheckClick = useCallback(() => {
    dispatch(settingsActions.saveInvertXMouseAxis(!invertMouseX));
  }, [dispatch, invertMouseX]);

  const onInvertMouseYCheckClick = useCallback(() => {
    dispatch(settingsActions.saveInvertYMouseAxis(!invertMouseY));
  }, [dispatch, invertMouseY]);

  const onShouldDisplayHeightCalcGroupPhotosClick = useCallback(() => {
    dispatch(
      settingsActions.saveShouldDisplayHeightCalcGroupPhoto(
        !shouldDisplayHeightCalcGroupPhotos
      )
    );
  }, [dispatch, shouldDisplayHeightCalcGroupPhotos]);

  const onAutoCenterCameraClick = useCallback(() => {
    dispatch(settingsActions.saveAutoCenterCamera(!autoCenterCamera));
  }, [autoCenterCamera, dispatch]);

  const onSettingsContainerScroll = useCallback((event: React.UIEvent) => {
    const scrollContainer = event.target as HTMLDivElement;
    if (scrollContainer.scrollTop > 5) {
      setHeaderStyle({ borderBottom: "2px solid rgba(0, 0, 0, 0.15)" });
    } else {
      setHeaderStyle({});
    }
  }, []);

  if (!isVisible) return null;

  return (
    <Container>
      <Header style={headerStyle}>
        <Title>{"Settings"}</Title>
        <Button
          styles={{ width: 16, height: 16, color: "#20324a" }}
          onClick={onCloseButtonClick}
          tooltip={"Close setttings modal"}
        >
          <Icon iconSrc={"close"} />
        </Button>
      </Header>
      <SettingsContainer onScroll={onSettingsContainerScroll}>
        <SectionContainer>
          <SubTitle>{"Mouse options"}</SubTitle>
          <OptionContainer>
            <OptionLabel>{"Invert mouse X axis:"}</OptionLabel>
            <CheckButton onClick={onInvertMouseXCheckClick}>
              {invertMouseX ? <Icon iconSrc={"close"} /> : <></>}
            </CheckButton>
          </OptionContainer>
          <OptionContainer>
            <OptionLabel>{"Invert mouse Y axis:"}</OptionLabel>
            <CheckButton onClick={onInvertMouseYCheckClick}>
              {invertMouseY ? <Icon iconSrc={"close"} /> : <></>}
            </CheckButton>
          </OptionContainer>
        </SectionContainer>
        <SectionContainer>
          <SubTitle>{"Orbit Viewer options"}</SubTitle>
          <OptionContainer>
            <OptionLabel>
              {"Display photos used in tower height calculation estimated:"}
            </OptionLabel>
            <CheckButton onClick={onShouldDisplayHeightCalcGroupPhotosClick}>
              {shouldDisplayHeightCalcGroupPhotos ? (
                <Icon iconSrc={"close"} />
              ) : (
                <></>
              )}
            </CheckButton>
          </OptionContainer>
          <OptionContainer>
            <OptionLabel>{"Auto center camera:"}</OptionLabel>
            <CheckButton onClick={onAutoCenterCameraClick}>
              {autoCenterCamera ? <Icon iconSrc={"close"} /> : <></>}
            </CheckButton>
          </OptionContainer>
        </SectionContainer>
        <SectionContainer style={{ marginTop: 4 }}>
          <SubTitle>{"Colors options"}</SubTitle>
          <OptionContainer>
            <OptionLabel>{"Selected photo:"}</OptionLabel>
            <ColorPicker
              currentColor={selectedPhotoColor}
              onChange={onSelectPhotoColorChange}
            />
          </OptionContainer>
          <OptionContainer>
            <OptionLabel>{"In VR2D photo:"}</OptionLabel>
            <ColorPicker
              currentColor={vr2dPhotoColor}
              onChange={onVr2dPhotoColorChange}
            />
          </OptionContainer>
          <OptionContainer>
            <OptionLabel>{"Outlier photo:"}</OptionLabel>
            <ColorPicker
              currentColor={outlierPhotoColor}
              onChange={onOutlierPhotoColorChange}
            />
          </OptionContainer>
          <OptionContainer>
            <OptionLabel>
              {"Tower height calculation estimated photo:"}
            </OptionLabel>
            <ColorPicker
              currentColor={heightCalcGroupPhotoColor}
              onChange={onHeightCalcGroupPhotoColorChange}
            />
          </OptionContainer>
          <OptionContainer>
            <OptionLabel>{"Informed tower:"}</OptionLabel>
            <ColorPicker
              currentColor={informedTowerColor}
              onChange={onInformedTowerColorChange}
            />
          </OptionContainer>
          <OptionContainer>
            <OptionLabel>{"Estimated tower:"}</OptionLabel>
            <ColorPicker
              currentColor={estimatedTowerColor}
              onChange={onEstimatedTowerColorChange}
            />
          </OptionContainer>
        </SectionContainer>
      </SettingsContainer>
    </Container>
  );
};

export default SettingsModal;
