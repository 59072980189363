import { SettingsActionType, SettingsAction } from "./types";

const actions = {
  setVisible: (isVisible: boolean): SettingsAction => ({
    payload: { isVisible },
    type: SettingsActionType.SET_VISIBLE,
  }),
  setSelectedPhotoColor: (selectedPhotoColor: string): SettingsAction => ({
    payload: { selectedPhotoColor },
    type: SettingsActionType.SET_SELECTED_PHOTO_COLOR,
  }),
  setVr2dPhotoColor: (vr2dPhotoColor: string): SettingsAction => ({
    payload: { vr2dPhotoColor },
    type: SettingsActionType.SET_VR2D_PHOTO_COLOR,
  }),
  setOutlierPhotoColor: (outlierPhotoColor: string): SettingsAction => ({
    payload: { outlierPhotoColor },
    type: SettingsActionType.SET_OUTLIER_PHOTO_COLOR,
  }),
  setHeightCalcGroupPhotoColor: (
    heightCalcGroupPhotoColor: string
  ): SettingsAction => ({
    payload: { heightCalcGroupPhotoColor },
    type: SettingsActionType.SET_HEIGHT_CALC_GROUP_PHOTO_COLOR,
  }),
  setInformedTowerColor: (informedTowerColor: string): SettingsAction => ({
    payload: { informedTowerColor },
    type: SettingsActionType.SET_INFORMED_TOWER_COLOR,
  }),
  setEstimatedTowerColor: (estimatedTowerColor: string): SettingsAction => ({
    payload: { estimatedTowerColor },
    type: SettingsActionType.SET_ESTIMATED_TOWER_COLOR,
  }),
  setInvertXMouseAxis: (invertXMouseAxis: boolean): SettingsAction => ({
    payload: { invertXMouseAxis },
    type: SettingsActionType.SET_INVERT_X_MOUSE_AXIS,
  }),
  setInvertYMouseAxis: (invertYMouseAxis: boolean): SettingsAction => ({
    payload: { invertYMouseAxis },
    type: SettingsActionType.SET_INVERT_Y_MOUSE_AXIS,
  }),
  setShouldDisplayHeightCalcGroupPhoto: (
    shouldDisplayHeightCalcGroupPhoto: boolean
  ): SettingsAction => ({
    payload: { shouldDisplayHeightCalcGroupPhoto },
    type: SettingsActionType.SET_SHOULD_DISPLAY_HEIGHT_CALC_GROUP_PHOTO,
  }),
  setAutoCenterCamera: (autoCenterCamera: boolean): SettingsAction => ({
    payload: { autoCenterCamera },
    type: SettingsActionType.SET_AUTO_CENTER_CAMERA,
  }),
  saveSelectedPhotoColor: (selectedPhotoColor: string): SettingsAction => ({
    payload: { selectedPhotoColor },
    type: SettingsActionType.SAVE_SELECTED_PHOTO_COLOR,
  }),
  saveVr2dPhotoColor: (vr2dPhotoColor: string): SettingsAction => ({
    payload: { vr2dPhotoColor },
    type: SettingsActionType.SAVE_VR2D_PHOTO_COLOR,
  }),
  saveOutlierPhotoColor: (outlierPhotoColor: string): SettingsAction => ({
    payload: { outlierPhotoColor },
    type: SettingsActionType.SAVE_OUTLIER_PHOTO_COLOR,
  }),
  saveHeightCalcGroupPhotoColor: (
    heightCalcGroupPhotoColor: string
  ): SettingsAction => ({
    payload: { heightCalcGroupPhotoColor },
    type: SettingsActionType.SAVE_HEIGHT_CALC_GROUP_PHOTO_COLOR,
  }),
  saveInformedTowerColor: (informedTowerColor: string): SettingsAction => ({
    payload: { informedTowerColor },
    type: SettingsActionType.SAVE_INFORMED_TOWER_COLOR,
  }),
  saveEstimatedTowerColor: (estimatedTowerColor: string): SettingsAction => ({
    payload: { estimatedTowerColor },
    type: SettingsActionType.SAVE_ESTIMATED_TOWER_COLOR,
  }),
  saveInvertXMouseAxis: (invertXMouseAxis: boolean): SettingsAction => ({
    payload: { invertXMouseAxis },
    type: SettingsActionType.SAVE_INVERT_X_MOUSE_AXIS,
  }),
  saveInvertYMouseAxis: (invertYMouseAxis: boolean): SettingsAction => ({
    payload: { invertYMouseAxis },
    type: SettingsActionType.SAVE_INVERT_Y_MOUSE_AXIS,
  }),
  saveShouldDisplayHeightCalcGroupPhoto: (
    shouldDisplayHeightCalcGroupPhoto: boolean
  ): SettingsAction => ({
    payload: { shouldDisplayHeightCalcGroupPhoto },
    type: SettingsActionType.SAVE_SHOULD_DISPLAY_HEIGHT_CALC_GROUP_PHOTO,
  }),
  saveAutoCenterCamera: (autoCenterCamera: boolean): SettingsAction => ({
    payload: { autoCenterCamera },
    type: SettingsActionType.SAVE_AUTO_CENTER_CAMERA,
  }),
  loadSettings: (): SettingsAction => ({
    type: SettingsActionType.LOAD_SETTINGS,
  }),
  clearState: (): SettingsAction => ({
    type: SettingsActionType.CLEAR,
  }),
};

export default actions;
