import { DirectionActions, PhotoData } from "../../../types";
import { UpdatePositionHandlers } from "./types";

const forwardPositionHandlers: UpdatePositionHandlers = {
  isApplicable: (action: DirectionActions) => {
    return action === DirectionActions.FORWARD;
  },
  apply: (currentPhotoData: PhotoData) => {
    const nextPhotoIndex = currentPhotoData.forward;
    if (nextPhotoIndex !== 0 && !nextPhotoIndex)
      return [currentPhotoData.index];
    return [nextPhotoIndex];
  },
};

export default forwardPositionHandlers;
