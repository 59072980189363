import {
  ImagesDataAction,
  ImagesDataActionType,
  ImagesDataPayload,
  ImagesDataReducer,
  ImagesDataState,
} from "./types";

export const initialState: ImagesDataState = {
  loading: true,
};

const setData: ImagesDataReducer = (
  state: ImagesDataState,
  payload?: ImagesDataPayload
) => ({
  ...state,
  data: payload?.data,
  initialLoaded: !!payload?.data,
});
const setError: ImagesDataReducer = (
  state: ImagesDataState,
  payload?: ImagesDataPayload
) => ({
  ...state,
  error: payload?.error,
  initialLoaded: true,
});
const setLoading: ImagesDataReducer = (
  state: ImagesDataState,
  payload?: ImagesDataPayload
) => ({
  ...state,
  loading: !!payload?.loading,
});

const clearState: ImagesDataReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<ImagesDataActionType, ImagesDataReducer>([
  [ImagesDataActionType.SET_DATA, setData],
  [ImagesDataActionType.SET_LOADING, setLoading],
  [ImagesDataActionType.SET_ERROR, setError],
  [ImagesDataActionType.CLEAR, clearState],
]);

const reducer = (
  state: ImagesDataState = initialState,
  action: ImagesDataAction
): ImagesDataState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
