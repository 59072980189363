import { put, takeLatest, call } from "redux-saga/effects";
import { SettingsAction, SettingsActionType } from "./types";
import actions from "./actions";
import * as repository from "../../repository/settings";

export function* saveSelectedPhotoColor({ payload }: SettingsAction) {
  if (!payload) return;
  const { selectedPhotoColor } = payload;
  if (!selectedPhotoColor) return;
  try {
    yield call(repository.saveSelectedPhotoColor, selectedPhotoColor);
    yield put(actions.setSelectedPhotoColor(selectedPhotoColor));
  } catch (_) {}
}

export function* saveVr2dPhotoColor({ payload }: SettingsAction) {
  if (!payload) return;
  const { vr2dPhotoColor } = payload;
  if (!vr2dPhotoColor) return;
  try {
    yield call(repository.saveVr2dPhotoColor, vr2dPhotoColor);
    yield put(actions.setVr2dPhotoColor(vr2dPhotoColor));
  } catch (_) {}
}

export function* saveOutlierPhotoColor({ payload }: SettingsAction) {
  if (!payload) return;
  const { outlierPhotoColor } = payload;
  if (!outlierPhotoColor) return;
  try {
    yield call(repository.saveOutlierPhotoColor, outlierPhotoColor);
    yield put(actions.setOutlierPhotoColor(outlierPhotoColor));
  } catch (_) {}
}

export function* saveHeightCalcGroupPhotoColor({ payload }: SettingsAction) {
  if (!payload) return;
  const { heightCalcGroupPhotoColor } = payload;
  if (!heightCalcGroupPhotoColor) return;
  try {
    yield call(
      repository.saveHeightCalcGroupPhotoColor,
      heightCalcGroupPhotoColor
    );
    yield put(actions.setHeightCalcGroupPhotoColor(heightCalcGroupPhotoColor));
  } catch (_) {}
}

export function* saveInformedTowerColor({ payload }: SettingsAction) {
  if (!payload) return;
  const { informedTowerColor } = payload;
  if (!informedTowerColor) return;
  try {
    yield call(repository.saveInformedTowerColor, informedTowerColor);
    yield put(actions.setInformedTowerColor(informedTowerColor));
  } catch (_) {}
}

export function* saveEstimatedTowerColor({ payload }: SettingsAction) {
  if (!payload) return;
  const { estimatedTowerColor } = payload;
  if (!estimatedTowerColor) return;
  try {
    yield call(repository.saveEstimatedTowerColor, estimatedTowerColor);
    yield put(actions.setEstimatedTowerColor(estimatedTowerColor));
  } catch (_) {}
}

export function* saveInvertXMouseAxis({ payload }: SettingsAction) {
  if (!payload) return;
  const { invertXMouseAxis } = payload;
  try {
    yield call(repository.saveInvertXMouseAxis, !!invertXMouseAxis);
    yield put(actions.setInvertXMouseAxis(!!invertXMouseAxis));
  } catch (_) {}
}

export function* saveInvertYMouseAxis({ payload }: SettingsAction) {
  if (!payload) return;
  const { invertYMouseAxis } = payload;
  try {
    yield call(repository.saveInvertYMouseAxis, !!invertYMouseAxis);
    yield put(actions.setInvertYMouseAxis(!!invertYMouseAxis));
  } catch (_) {}
}

export function* saveShouldDisplayHeightCalcGroupPhoto({
  payload,
}: SettingsAction) {
  if (!payload) return;
  const { shouldDisplayHeightCalcGroupPhoto } = payload;
  try {
    yield call(
      repository.saveShouldDisplayHeightCalcGroupPhoto,
      !!shouldDisplayHeightCalcGroupPhoto
    );
    yield put(
      actions.setShouldDisplayHeightCalcGroupPhoto(
        !!shouldDisplayHeightCalcGroupPhoto
      )
    );
  } catch (_) {}
}

export function* saveAutoCenterCamera({ payload }: SettingsAction) {
  if (!payload) return;
  const { autoCenterCamera } = payload;
  try {
    yield call(repository.saveAutoCenterCamera, !!autoCenterCamera);
    yield put(actions.setAutoCenterCamera(!!autoCenterCamera));
  } catch (_) {}
}

export function* loadSettings() {
  try {
    const invertXMouseAxis: boolean = yield call(
      repository.getInvertXMouseAxis
    );
    yield put(actions.setInvertXMouseAxis(invertXMouseAxis));

    const invertYMouseAxis: boolean = yield call(
      repository.getInvertYMouseAxis
    );
    yield put(actions.setInvertYMouseAxis(invertYMouseAxis));

    const shouldDisplayHeightCalcGroupPhoto: boolean = yield call(
      repository.getShouldDisplayHeightCalcGroupPhoto
    );
    yield put(
      actions.setShouldDisplayHeightCalcGroupPhoto(
        shouldDisplayHeightCalcGroupPhoto
      )
    );

    const autoCenterCamera: boolean = yield call(
      repository.getAutoCenterCamera
    );
    yield put(actions.setAutoCenterCamera(autoCenterCamera));

    const selectedPhotoColor: string = yield call(
      repository.getSelectedPhotoColor
    );
    yield put(actions.setSelectedPhotoColor(selectedPhotoColor));

    const vr2dPhotoColor: string = yield call(repository.getVr2dPhotoColor);
    yield put(actions.setVr2dPhotoColor(vr2dPhotoColor));

    const outlierPhotoColor: string = yield call(
      repository.getOutlierPhotoColor
    );
    yield put(actions.setOutlierPhotoColor(outlierPhotoColor));

    const heightCalcGroupPhotoColor: string = yield call(
      repository.getHeightCalcGroupPhotoColor
    );
    yield put(actions.setHeightCalcGroupPhotoColor(heightCalcGroupPhotoColor));

    const informedTowerColor: string = yield call(
      repository.getInformedTowerColor
    );
    yield put(actions.setInformedTowerColor(informedTowerColor));

    const estimatedTowerColor: string = yield call(
      repository.getEstimatedTowerColor
    );
    yield put(actions.setEstimatedTowerColor(estimatedTowerColor));
  } catch (_) {}
}

const sagas = [
  takeLatest(
    SettingsActionType.SAVE_SELECTED_PHOTO_COLOR,
    saveSelectedPhotoColor
  ),
  takeLatest(SettingsActionType.SAVE_VR2D_PHOTO_COLOR, saveVr2dPhotoColor),
  takeLatest(
    SettingsActionType.SAVE_OUTLIER_PHOTO_COLOR,
    saveOutlierPhotoColor
  ),
  takeLatest(
    SettingsActionType.SAVE_HEIGHT_CALC_GROUP_PHOTO_COLOR,
    saveHeightCalcGroupPhotoColor
  ),
  takeLatest(
    SettingsActionType.SAVE_INFORMED_TOWER_COLOR,
    saveInformedTowerColor
  ),
  takeLatest(
    SettingsActionType.SAVE_ESTIMATED_TOWER_COLOR,
    saveEstimatedTowerColor
  ),
  takeLatest(SettingsActionType.SAVE_INVERT_X_MOUSE_AXIS, saveInvertXMouseAxis),
  takeLatest(SettingsActionType.SAVE_INVERT_Y_MOUSE_AXIS, saveInvertYMouseAxis),
  takeLatest(
    SettingsActionType.SAVE_SHOULD_DISPLAY_HEIGHT_CALC_GROUP_PHOTO,
    saveShouldDisplayHeightCalcGroupPhoto
  ),
  takeLatest(SettingsActionType.SAVE_AUTO_CENTER_CAMERA, saveAutoCenterCamera),
  takeLatest(SettingsActionType.LOAD_SETTINGS, loadSettings),
];

export default sagas;
