import { DirectionActions, PhotoData } from "../../../types";
import { UpdatePositionHandlers } from "./types";

const leftPositionHandlers: UpdatePositionHandlers = {
  isApplicable: (action: DirectionActions) => {
    return action === DirectionActions.LEFT;
  },
  apply: (currentPhotoData: PhotoData) => {
    const nextPhotoIndex = currentPhotoData.left;
    if (nextPhotoIndex !== 0 && !nextPhotoIndex)
      return [currentPhotoData.index];
    return [nextPhotoIndex];
  },
};

export default leftPositionHandlers;
