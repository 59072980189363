import { put, takeLatest, call, select } from "redux-saga/effects";
import {
  DirectionActions,
  PhotoData,
  SelectionMode,
  UserInteraction,
} from "../../types";
import selectors from "./selectors";
import actions from "./actions";
import { CurrentPhotoDataAction, CurrentPhotoDataActionType } from "./types";
import downPositionHandlers from "./updatePositionHandlers/downPositionHandlers";
import leftPositionHandlers from "./updatePositionHandlers/leftPositionHandlers";
import rightPositionHandlers from "./updatePositionHandlers/rightPositionHandlers";
import customPositionHandlers from "./updatePositionHandlers/customPositionHandlers";
import straightIndexPositionHandler from "./updatePositionHandlers/straightIndexPositionHandler";
import { UpdatePositionHandlers } from "./updatePositionHandlers/types";
import upPositionHandlers from "./updatePositionHandlers/upPositionHandlers";
import imagesDataSelectors from "../ImagesData/selectors";
import backPositionHandlers from "./updatePositionHandlers/backPositionHandlers";
import forwardPositionHandlers from "./updatePositionHandlers/forwardPositionHandlers";

const getPhotoIndexFromList = (
  userInteraction: UserInteraction,
  currentPhotoData: PhotoData
) => {
  let currentPhotoIndex = currentPhotoData ? [currentPhotoData.index] : [0];
  const { directionAction, deltaX, deltaY, newIndex } = userInteraction;
  if (!directionAction) return currentPhotoIndex;
  const updatePositionHandlers: UpdatePositionHandlers[] = [
    rightPositionHandlers,
    downPositionHandlers,
    leftPositionHandlers,
    upPositionHandlers,
    backPositionHandlers,
    forwardPositionHandlers,
    customPositionHandlers,
    straightIndexPositionHandler,
  ];

  for (const handler of updatePositionHandlers) {
    if (handler.isApplicable(directionAction)) {
      currentPhotoIndex = handler.apply(
        currentPhotoData,
        deltaX,
        deltaY,
        newIndex
      );
      break;
    }
  }
  return currentPhotoIndex;
};

export function* getPhotoDataByIndex(photoIndex?: number) {
  if (!photoIndex && photoIndex !== 0) return;
  const photos: PhotoData[] = yield select(imagesDataSelectors.getPhotos);
  return photos.find(({ index }: PhotoData) => index === photoIndex);
}

export function* getPhotoByNewIndex(
  photoIndex: number[],
  currentPhoto: PhotoData
) {
  if (photoIndex.length === 1) {
    const photoData: PhotoData | undefined = yield call(
      getPhotoDataByIndex,
      photoIndex[0]
    );
    return photoData;
  }
  const hIndex = photoIndex[0];
  const vIndex = photoIndex[1];
  let photo = currentPhoto;

  for (let h = 0; h < Math.abs(hIndex); ++h) {
    const indx = hIndex > 0 ? photo.right : photo.left;
    if (!indx) break;
    photo = yield call(getPhotoDataByIndex, indx);
  }
  for (let v = 0; v < Math.abs(vIndex); ++v) {
    const indx = vIndex > 0 ? photo.up : photo.down;
    if (!indx) break;
    photo = yield call(getPhotoDataByIndex, indx);
  }
  return photo;
}

export function* validateNewPhotoBySelectionMode(
  photoData: PhotoData | undefined,
  directionAction?: DirectionActions
) {
  const selectedMode: SelectionMode = yield select(selectors.getSelectionMode);
  if (!photoData) return;
  const photoInVR = !photoData.is_outlier;
  if (
    ![
      DirectionActions.STRAIGHT_INDEX,
      DirectionActions.FORWARD,
      DirectionActions.BACK,
    ].includes(directionAction as DirectionActions) &&
    selectedMode === SelectionMode.VR2D
  ) {
    return photoInVR;
  }

  return true;
}

export function* getCurrentPhoto(userInteraction: UserInteraction) {
  const currentPhoto: PhotoData = yield select(selectors.getCurrentPhoto);
  const photoIndex: number[] = yield call(
    getPhotoIndexFromList,
    userInteraction,
    currentPhoto
  );
  const newPhotoData: PhotoData | undefined = yield call(
    getPhotoByNewIndex,
    photoIndex,
    currentPhoto
  );
  const isValid: boolean = yield call(
    validateNewPhotoBySelectionMode,
    newPhotoData,
    userInteraction.directionAction
  );
  if (isValid) return newPhotoData;
  return currentPhoto;
}

export function* updateCurrentPhoto({ payload }: CurrentPhotoDataAction) {
  if (!payload) return;
  const { userInteraction } = payload;
  if (!userInteraction) return;
  const currentPhoto: PhotoData = yield call(getCurrentPhoto, userInteraction);

  if (currentPhoto) yield put(actions.setCurrentPhotoData(currentPhoto));
}
export function* getInitialPhotoData() {
  const initialPhotoDataIndex: number = yield select(
    imagesDataSelectors.getInitialPhotoIndex
  );
  const newCurrentPhoto: PhotoData = yield call(
    getPhotoDataByIndex,
    initialPhotoDataIndex
  );
  if (newCurrentPhoto) yield put(actions.setCurrentPhotoData(newCurrentPhoto));
}

const profileSagas = [
  takeLatest(
    CurrentPhotoDataActionType.UPDATE_CURRENT_PHOTO,
    updateCurrentPhoto
  ),
  takeLatest(
    CurrentPhotoDataActionType.GET_INITIAL_PHOTO_DATA,
    getInitialPhotoData
  ),
];

export default profileSagas;
