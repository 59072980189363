export type ImageCoordinates = {
  x: number;
  y: number;
};

export type ViewersVisibleState = {
  photoViewer: boolean;
  orbitViewer: boolean;
};

export type OrbitPhotoVisibleState = {
  inVR: boolean;
  outlier: boolean;
  inHeightCalcGroup: boolean;
};
export type TowerDisplayVisibleState = {
  estimated: boolean;
  informed: boolean;
};

export enum ViewersState {
  PICTURE_N_PICTURE = "PICTURE_N_PICTURE",
  ONLY_ORBIT_VIEW = "ONLY_ORBIT_VIEW",
  ONLY_PHOTO_VIEW = "ONLY_PHOTO_VIEW",
  SIDE_BY_SIDE = "SIDE_BY_SIDE",
}

export type ImageData = {
  path: string;
  id: string;
};

export type ImageCross = {
  topImage?: ImageData;
  downImage?: ImageData;
  middleImage?: ImageData;
  rightImage?: ImageData;
  leftImage?: ImageData;
};

export type CanvasCoordinates = {
  x: number;
  y: number;
};

export enum InteractionMode {
  DEFAULT = "DEFAULT",
  PAN = "PAN",
}

export type CanvasImage = {
  image: HTMLImageElement | undefined;
  fullSizeImage: HTMLImageElement | undefined;
};

export enum DirectionActions {
  UP = "UP",
  DOWN = "DOWN",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  FORWARD = "FORWARD",
  BACK = "BACK",
  CUSTOM = "CUSTOM",
  STRAIGHT_INDEX = "STRAIGHT_INDEX",
}
export enum ZoomActions {
  IN = "IN",
  OUT = "OUT",
  CLEAR = "CLEAR",
}

export enum SelectionMode {
  VR2D = "VR2D",
  FREE = "FREE",
}

export type UserInteraction = {
  directionAction?: DirectionActions;
  zoomAction?: ZoomActions;
  deltaX?: number;
  deltaY?: number;
  newIndex?: number;
};

export enum SplitScreenMode {
  SPLIT = "SPLIT",
  PHOTO_VIEWER = "PHOTO_MATRIX",
  ORBIT_VIEWER = "ORBIT_VIEWER",
}

export type PhotoData = {
  file: string;
  index: number;
  latitude: number;
  longitude: number;
  absolute_altitude: number;
  relative_altitude?: number;
  normalized_absolute_altitude?: number;
  yaw: number;
  left: number;
  right: number;
  up?: number;
  down?: number;
  forward?: number;
  back?: number;
  is_outlier: boolean;
  in_height_calc_group: boolean;
  cluster: number;
};

export type TowerData = {
  estimated_tower_height: number;
  informed_tower_height: number;
  estimated_longitude: number;
  estimated_latitude: number;
  estimated_latitude_in_degrees: number;
  estimated_longitude_in_degrees: number;
};

export type PhotoDownloadData = {
  index: number;
  distance: number;
};

export type ImagesMetaData = {
  id: string;
  images_base_url: string;
  images_full_size_url: string;
  detail?: string;
  qc_report_url: string;
  result: {
    code_version: string | null | undefined;
    code_commit: string | null | undefined;
    version: string | number | null | undefined;
    photos: PhotoData[];
    photos_download: PhotoDownloadData[];
    first_photo?: number;
    tower_data: TowerData;
  };
};

export type LoadedImageData = {
  image?: HTMLImageElement;
  loaded?: boolean;
  id: string;
};
