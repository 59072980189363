import { all } from "redux-saga/effects";
import imagesDataSagas from "./ImagesData/sagas";
import currentPhotoDataSagas from "./CurrentPhotoData/sagas";
import settingsSagas from "./Settings/sagas";

function* rootSagas() {
  yield all([...imagesDataSagas]);
  yield all([...currentPhotoDataSagas]);
  yield all([...settingsSagas]);
}

export default rootSagas;
