import { keyframes } from "styled-components";

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 0;
  }
`;

export const fadeInFromLeft = keyframes`
0% {
    transform: translateX(-100%);
    opacity: 0;
}
100% {
    transform: translateX(0);
    opacity: 1;
}
`;
export const fadeInFromRight = keyframes`
0% {
    transform: translateX(100%);
    opacity: 0;
}
100% {
    transform: translateX(0);
    opacity: 1;
}
`;
