import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import imagesDataActions from "../store/ImagesData/actions";
import settingsActions from "../store/Settings/actions";

import imagesDataSelectors from "../store/ImagesData/selectors";

import { loadImage } from "../utils";

const useInitialLoad = () => {
  const [loadingInitialData, setLoadingInitialData] = useState(true);
  const loadedInitialImageRef = useRef(false);
  const error = useSelector(imagesDataSelectors.getError);
  const initialLoaded = useSelector(imagesDataSelectors.getInitialLoaded);
  const initialImage = useSelector(imagesDataSelectors.getInitialImageData);
  const dispatch = useDispatch();

  const fetchImagesData = useCallback(async () => {
    const params = new URLSearchParams(window.location.search);
    const projectId = params.get("id");
    dispatch(imagesDataActions.fetchImagesData(projectId!));
  }, [dispatch]);

  const removePreLoader = useCallback(() => {
    const preLoader = document.getElementById("preloader");
    if (!preLoader) return;
    document.body.removeChild(preLoader);
    setLoadingInitialData(false);
  }, []);

  const loadInitialImage = useCallback(async () => {
    if (initialLoaded && initialImage && !loadedInitialImageRef.current) {
      loadedInitialImageRef.current = true;
      const imageSrc = initialImage.path;
      const imageId = initialImage.id;
      try {
        await loadImage(imageSrc, imageId);
        setLoadingInitialData(false);
      } catch (e) {}
    }
  }, [initialLoaded, initialImage, loadedInitialImageRef]);

  useEffect(() => {
    if (!!error) {
      removePreLoader();
    }
  }, [error, removePreLoader]);

  useEffect(() => {
    fetchImagesData();
  }, [fetchImagesData]);

  useEffect(() => {
    if (initialLoaded) loadInitialImage();
  }, [initialLoaded, loadInitialImage]);

  useEffect(() => {
    dispatch(settingsActions.loadSettings());
    return () => {
      dispatch(imagesDataActions.clearState());
    };
  }, [dispatch]);

  return {
    error,
    loadingInitialData,
  };
};

export default useInitialLoad;
