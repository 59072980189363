import { useCallback } from "react";
import {
  DirectionActions,
  InteractionMode,
  SelectionMode,
  ZoomActions,
} from "../../types";

import Button from "../Button";
import { ScaleText } from "./styles";
import { PhotoViewerControlPanelProps } from "./types";
import Icon from "../Icon";
import { SubContainer } from "../ControlPanel/styles";
import { useSelector } from "react-redux";
import currentPhotoDataSelectors from "../../store/CurrentPhotoData/selectors";
import ControlPanelSection from "../ControlPanelSection";

const PhotoViewerControlPanel = ({
  interactionMode,
  userScale,
  showZoomSection,
  onDirectionButtonClick,
  onZoomButtonClick,
}: PhotoViewerControlPanelProps) => {
  const selectedMode = useSelector(currentPhotoDataSelectors.getSelectionMode);

  const onArrowButtonClick = useCallback(
    (action: DirectionActions) => {
      onDirectionButtonClick(action);
    },
    [onDirectionButtonClick]
  );
  const inPanMode = interactionMode === InteractionMode.PAN;
  const inVRMode = selectedMode === SelectionMode.VR2D;

  const displayModificatorButton = (direction: DirectionActions) =>
    !![DirectionActions.FORWARD, DirectionActions.BACK].includes(direction);

  const isHorizontalDirection = (direction: DirectionActions) =>
    !![DirectionActions.RIGHT, DirectionActions.LEFT].includes(direction);

  const formatDirectionKey = (directionAction: DirectionActions) => {
    if (directionAction === DirectionActions.BACK) {
      directionAction = DirectionActions.DOWN;
    }
    if (directionAction === DirectionActions.FORWARD) {
      directionAction = DirectionActions.UP;
    }
    return directionAction.toLowerCase();
  };

  const getArrowToottip = useCallback(
    (directionAction: DirectionActions) => {
      const direction = directionAction.toLowerCase();
      const actionWord = isHorizontalDirection(directionAction)
        ? "Rotate"
        : "Move";
      let tooltip = inPanMode
        ? `Drag the view ${direction}`
        : `${actionWord} ${direction}`;
      const modificatorButton = displayModificatorButton(directionAction)
        ? "Shift + "
        : "";

      const directionKey = formatDirectionKey(directionAction);
      const keyshortcut = `(${modificatorButton}${directionKey} arrow key)`;
      return `${tooltip} ${keyshortcut}`;
    },
    [inPanMode]
  );

  const getArrowIcon = useCallback(
    (direction: DirectionActions) => {
      switch (direction) {
        case DirectionActions.LEFT:
          return inPanMode ? "arrow_back" : "rotate_left";
        case DirectionActions.RIGHT:
          return inPanMode ? "arrow_forward" : "rotate_right";
        case DirectionActions.UP:
          return "arrow_upward";
        case DirectionActions.DOWN:
          return "arrow_downward";
        default:
          return "";
      }
    },
    [inPanMode]
  );

  return (
    <ControlPanelSection>
      <SubContainer>
        <Button
          onClick={() => onArrowButtonClick(DirectionActions.LEFT)}
          tooltip={getArrowToottip(DirectionActions.LEFT)}
        >
          <Icon iconSrc={getArrowIcon(DirectionActions.LEFT)} />
        </Button>
        <Button
          onClick={() => onArrowButtonClick(DirectionActions.RIGHT)}
          tooltip={getArrowToottip(DirectionActions.RIGHT)}
        >
          <Icon iconSrc={getArrowIcon(DirectionActions.RIGHT)} />
        </Button>
        <Button
          onClick={() => onArrowButtonClick(DirectionActions.UP)}
          tooltip={getArrowToottip(DirectionActions.UP)}
        >
          <Icon iconSrc={getArrowIcon(DirectionActions.UP)} />
        </Button>
        <Button
          onClick={() => onArrowButtonClick(DirectionActions.DOWN)}
          tooltip={getArrowToottip(DirectionActions.DOWN)}
        >
          <Icon iconSrc={getArrowIcon(DirectionActions.DOWN)} />
        </Button>
      </SubContainer>
      {!inVRMode && !inPanMode && (
        <SubContainer>
          <Button
            onClick={() => onArrowButtonClick(DirectionActions.FORWARD)}
            tooltip={getArrowToottip(DirectionActions.FORWARD)}
          >
            <Icon iconSrc={"upload"} />
          </Button>
          <Button
            onClick={() => onArrowButtonClick(DirectionActions.BACK)}
            tooltip={getArrowToottip(DirectionActions.BACK)}
          >
            <Icon iconSrc={"upload"} inverted />
          </Button>
        </SubContainer>
      )}
      <ControlPanelSection isVisible={showZoomSection} maxWidth={120}>
        <Button
          onClick={() => onZoomButtonClick(ZoomActions.OUT)}
          tooltip={"Zoom out"}
        >
          <Icon iconSrc={"zoom_out"} />
        </Button>
        <Button
          onClick={() => onZoomButtonClick(ZoomActions.IN)}
          tooltip={"Zoom in"}
        >
          <Icon iconSrc={"zoom_in"} />
        </Button>

        <ScaleText>{`${Math.floor(userScale)}%`}</ScaleText>
        <Button
          onClick={() => onZoomButtonClick(ZoomActions.CLEAR)}
          tooltip={"Reset zoom"}
        >
          <Icon iconSrc={"zoom_in_map"} />
        </Button>
      </ControlPanelSection>
    </ControlPanelSection>
  );
};
export default PhotoViewerControlPanel;
