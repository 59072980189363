import { ReactChild } from "react";

import ErrorMessage from "../../components/ErrorMessage";
import useInitialLoad from "../../hooks/useInitialLoad";

export type ImagesDataProviderProps = {
  children: ReactChild;
};

const InitialLoader = ({ children }: ImagesDataProviderProps) => {
  const { error, loadingInitialData } = useInitialLoad();

  return (
    <>
      {!loadingInitialData && error ? (
        <ErrorMessage message={error.message} />
      ) : (
        !loadingInitialData && children
      )}
    </>
  );
};

export default InitialLoader;
