import {
  Cartesian3,
  Color,
  SceneMode,
  Viewer as CesiumViewer,
  Ion,
  EventHelper,
  HeightReference,
} from "cesium";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CesiumComponentRef, Entity, Viewer, CylinderGraphics } from "resium";
import imagesDataSelectors from "../../store/ImagesData/selectors";
import currentPhotoDataSelectors from "../../store/CurrentPhotoData/selectors";
import settingsSelectors from "../../store/Settings/selectors";
import { OrbitViewerProps } from "./types";
import ViewerContainer from "../ViewerContainer";
import currentPhotoDataActions from "../../store/CurrentPhotoData/actions";
import { DirectionActions, PhotoData, UserInteraction } from "../../types";
import LoadSpinner from "../LoadSpinner";
import { LoadingContainer } from "./styles";
import { hexToRgb } from "../../utils";

Ion.defaultAccessToken = process.env.REACT_APP_ION_ACCESS_TOKEN || "";
const MAX_RGB_VALUE = 255;
const OrbitViewer = ({
  isVisible,
  viewerSize,
  isFloatingWindow,
  transitionEnabled,
  startMinimized,
  orbitPhotoVisibleState,
  towerDisplayVisibleState,
  recenterCameraRef,
  onVisibleChange,
  onWindowFloatChange,
}: OrbitViewerProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const firstRecenterCameraRef = useRef(false);
  const cesiumViewerRef = useRef<CesiumViewer>();

  const selectedPhotoColor = useSelector(
    settingsSelectors.getSelectedPhotoColor
  );
  const autoCenterCamera = useSelector(settingsSelectors.getAutoCenterCamera);
  const shouldDisplayHeightCalcGroupPhotos = useSelector(
    settingsSelectors.shouldDisplayHeightCalcGroupPhotos
  );
  const vr2dPhotoColor = useSelector(settingsSelectors.getVr2dPhotoColor);
  const outlierPhotoColor = useSelector(settingsSelectors.getOutlierPhotoColor);
  const heightCalcGroupPhotoColor = useSelector(
    settingsSelectors.getHeightCalcGroupPhotoColor
  );
  const informedTowerColor = useSelector(
    settingsSelectors.getInformedTowerColor
  );
  const estimatedTowerColor = useSelector(
    settingsSelectors.getEstimatedTowerColor
  );
  const currentPhoto = useSelector(currentPhotoDataSelectors.getCurrentPhoto);
  const photos = useSelector(imagesDataSelectors.getPhotos);
  const towerData = useSelector(imagesDataSelectors.getTowerData);

  const dispatch = useDispatch();

  const getEntityColor = useCallback(
    (isSelected: boolean, inVR2d: boolean, inHeightCalcGroup: boolean) => {
      const defaultColor = hexToRgb(outlierPhotoColor);
      const inVR2Dcolor = hexToRgb(vr2dPhotoColor);
      const heightCalcGroupColor = hexToRgb(heightCalcGroupPhotoColor);
      const selectedColor = hexToRgb(selectedPhotoColor);

      let red = defaultColor.R;
      let green = defaultColor.G;
      let blue = defaultColor.B;
      let alpha = 0.5;

      if (inVR2d) {
        red = inVR2Dcolor.R;
        green = inVR2Dcolor.G;
        blue = inVR2Dcolor.B;
      }
      if (inHeightCalcGroup && shouldDisplayHeightCalcGroupPhotos) {
        red = heightCalcGroupColor.R;
        green = heightCalcGroupColor.G;
        blue = heightCalcGroupColor.B;
      }
      if (isSelected) {
        red = selectedColor.R;
        green = selectedColor.G;
        blue = selectedColor.B;
        alpha = 1;
      }

      const color = new Color(
        red / MAX_RGB_VALUE,
        green / MAX_RGB_VALUE,
        blue / MAX_RGB_VALUE,
        alpha
      );

      return color;
    },
    [
      heightCalcGroupPhotoColor,
      outlierPhotoColor,
      selectedPhotoColor,
      shouldDisplayHeightCalcGroupPhotos,
      vr2dPhotoColor,
    ]
  );

  const getEntityPixelSize = useCallback(
    (isSelected: boolean, inVR: boolean, inHeightCalcGroup: boolean) => {
      let pixelSize = 8;
      if (!orbitPhotoVisibleState) return pixelSize;
      const { inVR: inVRVisible, outlier: outlierVisible } =
        orbitPhotoVisibleState;
      if (inVR && !inVRVisible) pixelSize = -8;
      if (!inVR && !outlierVisible) pixelSize = -8;
      if (shouldDisplayHeightCalcGroupPhotos && inHeightCalcGroup)
        pixelSize = 8;
      if (isSelected) pixelSize = 20;
      return pixelSize;
    },
    [orbitPhotoVisibleState, shouldDisplayHeightCalcGroupPhotos]
  );

  const onEntityClick = useCallback(
    (photo: PhotoData) => {
      const { index } = photo;
      const userInteraction: UserInteraction = {
        directionAction: DirectionActions.STRAIGHT_INDEX,
        newIndex: index,
      };
      dispatch(currentPhotoDataActions.updateCurrentPhoto(userInteraction));
    },
    [dispatch]
  );

  const renderEntities = useMemo(() => {
    return photos.map((photo) => {
      const lng = photo.longitude;
      const lat = photo.latitude;
      const height = photo.relative_altitude;
      const isSelected = photo.index === currentPhoto?.index;
      const pixelSize = getEntityPixelSize(
        isSelected,
        !photo.is_outlier,
        photo.in_height_calc_group
      );
      const color = getEntityColor(
        isSelected,
        !photo.is_outlier,
        photo.in_height_calc_group
      );
      return (
        <Entity
          position={Cartesian3.fromDegrees(lng, lat, height)}
          point={{ pixelSize, color }}
          id={photo.index.toString()}
          key={photo.index}
          onClick={() => onEntityClick(photo)}
        />
      );
    });
  }, [
    photos,
    currentPhoto?.index,
    getEntityPixelSize,
    getEntityColor,
    onEntityClick,
  ]);

  const renderEstimatedTowerEntity = useMemo(() => {
    if (
      !towerData ||
      !towerData.estimated_tower_height ||
      !towerDisplayVisibleState.estimated
    )
      return null;
    const towerColor = hexToRgb(estimatedTowerColor);
    const color = new Color(
      towerColor.R / MAX_RGB_VALUE,
      towerColor.G / MAX_RGB_VALUE,
      towerColor.B / MAX_RGB_VALUE,
      0.7
    );
    return (
      <Entity
        position={Cartesian3.fromDegrees(
          towerData.estimated_longitude_in_degrees,
          towerData.estimated_latitude_in_degrees,
          0
        )}
      >
        <CylinderGraphics
          heightReference={HeightReference.CLAMP_TO_GROUND}
          length={towerData.estimated_tower_height}
          bottomRadius={1.5}
          topRadius={1.5}
          material={color}
        />
      </Entity>
    );
  }, [estimatedTowerColor, towerData, towerDisplayVisibleState.estimated]);

  const renderInformedTowerEntity = useMemo(() => {
    if (
      !towerData ||
      !towerData.informed_tower_height ||
      !towerDisplayVisibleState.informed
    )
      return null;

    const towerColor = hexToRgb(informedTowerColor);
    const color = new Color(
      towerColor.R / MAX_RGB_VALUE,
      towerColor.G / MAX_RGB_VALUE,
      towerColor.B / MAX_RGB_VALUE,
      0.5
    );

    return (
      <Entity
        position={Cartesian3.fromDegrees(
          towerData.estimated_longitude_in_degrees,
          towerData.estimated_latitude_in_degrees,
          0
        )}
      >
        <CylinderGraphics
          heightReference={HeightReference.CLAMP_TO_GROUND}
          length={towerData.informed_tower_height}
          bottomRadius={2}
          topRadius={2}
          material={color}
        />
      </Entity>
    );
  }, [informedTowerColor, towerData, towerDisplayVisibleState.informed]);

  const getViewerRef = (viewerRef: CesiumComponentRef<CesiumViewer> | null) => {
    if (!viewerRef) return;
    const { cesiumElement } = viewerRef;
    cesiumViewerRef.current = cesiumElement;
  };

  const flyToCurrentEntity = useCallback(
    (currentPhoto: PhotoData) => {
      if (cesiumViewerRef.current && currentPhoto) {
        const currentEntity = cesiumViewerRef.current.entities.getById(
          currentPhoto.index.toString()
        );
        const zoomToEntity = currentEntity || cesiumViewerRef.current.entities;
        cesiumViewerRef.current.flyTo(zoomToEntity, { duration: 0.3 });
      }
    },
    [cesiumViewerRef]
  );

  const recenterCamera = useCallback(() => {
    if (!currentPhoto) return;
    flyToCurrentEntity(currentPhoto);
  }, [currentPhoto, flyToCurrentEntity]);

  useEffect(() => {
    if (currentPhoto && (!firstRecenterCameraRef.current || autoCenterCamera)) {
      firstRecenterCameraRef.current = true;
      flyToCurrentEntity(currentPhoto);
    }
  }, [autoCenterCamera, currentPhoto, flyToCurrentEntity]);

  useEffect(() => {
    recenterCameraRef(recenterCamera);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recenterCamera]);

  useEffect(() => {
    if (!cesiumViewerRef.current) return;
    const helper = new EventHelper();
    helper.add(
      cesiumViewerRef.current.scene.globe.tileLoadProgressEvent,
      (event) => {
        if (event === 0) {
          setIsLoading(false);
        }
      }
    );
  }, []);

  return (
    <ViewerContainer
      title={"Orbit Viewer"}
      isVisible={isVisible}
      transitionEnabled={transitionEnabled}
      isFloatingWindow={isFloatingWindow}
      onVisibleChange={onVisibleChange}
      onWindowFloatChange={onWindowFloatChange}
      viewerSize={viewerSize}
      canFloat
      startMinimized={startMinimized}
    >
      <Viewer
        full
        animation={false}
        fullscreenButton={false}
        homeButton={false}
        infoBox={false}
        vrButton={false}
        baseLayerPicker={false}
        geocoder={false}
        sceneMode={SceneMode.SCENE3D}
        scene3DOnly
        timeline={false}
        ref={(viewer) => getViewerRef(viewer)}
      >
        {renderEntities}
        {renderEstimatedTowerEntity}
        {renderInformedTowerEntity}
      </Viewer>
      {isLoading && (
        <LoadingContainer>
          <LoadSpinner />
        </LoadingContainer>
      )}
    </ViewerContainer>
  );
};

export default OrbitViewer;
