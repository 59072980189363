import { useCallback, useEffect, useRef } from "react";
import { CanvasCoordinates, CanvasImage, UserInteraction } from "../types";
import customTranslateHandler from "./canvasTranslateHandlers/customTranslateHandler";
import downTranslateHandler from "./canvasTranslateHandlers/downTranslateHandler";
import leftTranslateHandler from "./canvasTranslateHandlers/leftTranslateHandler";
import normalizeTranslateHandler from "./canvasTranslateHandlers/normalizeTranslateHandler";
import rightTranslateHandler from "./canvasTranslateHandlers/rightTranslateHandler";
import { CanvasTranslateHandler } from "./canvasTranslateHandlers/types";
import upTranslateHandler from "./canvasTranslateHandlers/upTranslateHandler";
import { resizeImageKeepAspectRatio } from "../utils";

export type CanvasSize = {
  width: number;
  height: number;
};

const INITIAL_SCALE = 1;
const MAX_SCALE = 8;

const useDrawCanvas = (canvasContext: CanvasRenderingContext2D | undefined) => {
  const scaleRef = useRef(INITIAL_SCALE);
  const firstDraw = useRef(true);
  const translateRef = useRef<CanvasCoordinates>({ x: 0, y: 0 });
  const baseScaleRef = useRef<number>(1);
  const canvasSizeRef = useRef<CanvasSize>();
  const currentImageRef = useRef<HTMLImageElement>();

  const getCanvasSize = useCallback(() => {
    if (!canvasContext) return;
    const canvasWidth = canvasContext.canvas.width;
    const canvasHeight = canvasContext.canvas.height;
    const canvasSize = {
      width: canvasWidth,
      height: canvasHeight,
    };
    canvasSizeRef.current = canvasSize;
  }, [canvasContext]);

  const calcBaseScale = useCallback(() => {
    const image = currentImageRef.current;
    if (!image) return;
    if (!canvasSizeRef.current) return;
    const maxScale = 1.5;

    const scale = Math.min(
      resizeImageKeepAspectRatio(
        image.width,
        image.height,
        canvasSizeRef.current.width,
        canvasSizeRef.current.height
      ),
      maxScale
    );

    baseScaleRef.current = scale;
  }, [baseScaleRef, canvasSizeRef, currentImageRef]);

  const removePreLoader = useCallback(() => {
    const preLoader = document.getElementById("preloader");
    if (!preLoader) return;
    document.body.removeChild(preLoader);
    firstDraw.current = false;
  }, []);

  const drawCanvas = useCallback(() => {
    if (!canvasContext) return;
    const image = currentImageRef.current;
    if (!image) return;
    if (!canvasSizeRef.current) return;

    const scale = baseScaleRef.current * scaleRef.current;

    canvasContext.imageSmoothingEnabled = true;
    canvasContext.save();
    canvasContext.clearRect(
      0,
      0,
      canvasSizeRef.current.width,
      canvasSizeRef.current.height
    );
    canvasContext.scale(scale, scale);
    canvasContext.translate(
      canvasSizeRef.current.width / 2 / scale,
      canvasSizeRef.current.height / 2 / scale
    );
    const translateX = translateRef.current.x;
    const translateY = translateRef.current.y;
    canvasContext.translate(translateX, translateY);
    try {
      canvasContext.drawImage(image, -image.width / 2, -image.height / 2);
    } catch (e) {
      console.error(e);
    }
    canvasContext.restore();
    if (firstDraw.current) {
      removePreLoader();
    }
  }, [canvasContext, removePreLoader]);

  const setScale = useCallback((newScale: number) => {
    const maxScale = INITIAL_SCALE * MAX_SCALE;
    const minScale = INITIAL_SCALE;
    if (newScale >= maxScale) newScale = maxScale;
    if (newScale <= minScale) newScale = minScale;
    scaleRef.current = newScale;
    const _onScaled = newScale !== INITIAL_SCALE;
    if (!_onScaled) {
      translateRef.current = {
        x: 0,
        y: 0,
      };
    }
    return _onScaled;
  }, []);

  const getNewTranslatePosition = useCallback(
    (
      canvasTranslateHandler: CanvasTranslateHandler,
      deltaX?: number,
      deltaY?: number
    ) => {
      if (!canvasSizeRef.current) return;
      if (!currentImageRef.current) return;
      const scale = baseScaleRef.current * scaleRef.current;
      const currentImageSize = {
        width: currentImageRef.current.width,
        height: currentImageRef.current.height,
      };

      translateRef.current = canvasTranslateHandler.apply(
        translateRef.current,
        canvasSizeRef.current,
        currentImageSize,
        scale,
        deltaX,
        deltaY
      );
    },
    [canvasSizeRef, currentImageRef]
  );

  const translateCanvas = useCallback(
    (userUserInteraction: UserInteraction) => {
      const { directionAction, deltaX, deltaY } = userUserInteraction;
      if (!directionAction) return;
      const onScaled = scaleRef.current !== INITIAL_SCALE;
      if (!onScaled) return;
      const canvasTranslateHandlers: CanvasTranslateHandler[] = [
        downTranslateHandler,
        upTranslateHandler,
        rightTranslateHandler,
        leftTranslateHandler,
        customTranslateHandler,
      ];

      for (const handler of canvasTranslateHandlers) {
        if (handler.isApplicable(directionAction)) {
          getNewTranslatePosition(handler, deltaX, deltaY);
          break;
        }
      }
      drawCanvas();
    },
    [drawCanvas, getNewTranslatePosition]
  );

  const zoomIn = useCallback(
    (delta = 1) => {
      const newScale = scaleRef.current + (scaleRef.current / 10) * delta; 
      setScale(newScale);
      drawCanvas();
    },
    [scaleRef, drawCanvas, setScale]
  );

  const zoomOut = useCallback(
    (delta = 1) => {
      const newScale = scaleRef.current - (scaleRef.current / 10) * delta;

      if (setScale(newScale))
        getNewTranslatePosition(normalizeTranslateHandler);
      drawCanvas();
    },
    [setScale, getNewTranslatePosition, drawCanvas]
  );

  const clearZoom = useCallback(() => {
    setScale(INITIAL_SCALE);
    drawCanvas();
  }, [setScale, drawCanvas]);

  const updateCanvas = useCallback(
    (canvasImage: CanvasImage) => {
      const { image, fullSizeImage } = canvasImage;
      if (!image || !canvasContext) return;
      let currentImage = image;
      const onScaled = scaleRef.current !== INITIAL_SCALE;
      if (onScaled) currentImage = fullSizeImage || image;
      currentImageRef.current = currentImage;
      getCanvasSize();
      calcBaseScale();
      drawCanvas();
    },
    [canvasContext, getCanvasSize, drawCanvas, calcBaseScale]
  );

  useEffect(() => {
    getCanvasSize();
  }, [getCanvasSize]);

  const getUserScale = () => {
    return 100 * scaleRef.current;
  };
  const getOnScale = () => {
    return scaleRef.current !== INITIAL_SCALE;
  };

  return {
    updateCanvas,
    zoomIn,
    zoomOut,
    clearZoom,
    translateCanvas,
    getUserScale,
    getOnScale,
  };
};

export default useDrawCanvas;
