import styled, { keyframes } from "styled-components";

const rotate = keyframes`
from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

const Spinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 6px solid #c8cdd3;
  border-right-color: #243852;
  transform-origin: center;
  animation: ${rotate} 1s linear infinite;
`;
export { Spinner };
