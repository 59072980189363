import { Container } from "./styles";
import { DropdownMenuProps } from "./types";

const DropdownMenu = ({
  isVisible,
  direction,
  height = 40,
  children,
}: DropdownMenuProps) => {
  const initialPosition =
    direction === "FROM_TOP"
      ? { bottom: "-2px", left: "-25px", right: "unset", top: "unset" }
      : { top: "-2px", right: "20px", left: "unset", bottom: "unset" };
  return (
    <Container
      direction={direction}
      initialPosition={initialPosition}
      isVisible={isVisible}
      height={`${height}px`}
    >
      {children}
    </Container>
  );
};

export default DropdownMenu;
