import { useCallback, useEffect, useRef, useState } from "react";
import FloatButton from "../FloatButton";
import { Container } from "./styles";
import { ViewerContainerProps, ViewerAnimation } from "./types";

const ViewerContainer = ({
  children,
  viewerSize,
  isVisible,
  title,
  mirrored,
  canFloat,
  isFloatingWindow,
  startMinimized,
  transitionEnabled = true,
  onVisibleChange,
  onWindowFloatChange,
}: ViewerContainerProps) => {
  const initalViewSize = startMinimized
    ? { width: 200, height: 300 }
    : viewerSize;
  const containerRef = useRef<HTMLDivElement>();
  const [containerSize, setContainerSize] = useState(initalViewSize);
  const isVisibleIcon = isVisible ? "visibility" : "visibility_off";
  const [currentAnimation, setCurrentAnimation] =
    useState<ViewerAnimation | null>(null);

  const floatIcon = isFloatingWindow
    ? "fit_screen"
    : mirrored
    ? "picture_in_picture_alt"
    : "picture_in_picture";

  const onVisibleClick = useCallback(() => {
    const newVisibleState = !isVisible;
    onVisibleChange(newVisibleState);
  }, [isVisible, onVisibleChange]);

  useEffect(() => {
    if (transitionEnabled) {
      return setCurrentAnimation(ViewerAnimation.DEFAULT);
    }
    if (isFloatingWindow) {
      return setCurrentAnimation(ViewerAnimation.ENTER_FLOAT_ANIMATION);
    }
  }, [isFloatingWindow, transitionEnabled]);

  useEffect(() => {
    if (!isFloatingWindow) {
      setContainerSize(viewerSize);
    }
  }, [isFloatingWindow, viewerSize]);

  useEffect(() => {
    const currentAnimation = ViewerAnimation.DEFAULT;
    setCurrentAnimation(currentAnimation);
  }, [isVisible]);

  const onFloatWindowClick = useCallback(() => {
    const newIsFloatingWindow = !isFloatingWindow;
    if (!newIsFloatingWindow)
      return setCurrentAnimation(ViewerAnimation.LEAVE_FLOAT_ANIMATION);
    if (onWindowFloatChange) {
      onWindowFloatChange(newIsFloatingWindow);
    }
  }, [isFloatingWindow, onWindowFloatChange]);

  const onContainerAnimationEnd = useCallback(
    (target: Element) => {
      if (
        target === containerRef.current &&
        currentAnimation === ViewerAnimation.LEAVE_FLOAT_ANIMATION &&
        onWindowFloatChange
      ) {
        onWindowFloatChange(false);
        setCurrentAnimation(ViewerAnimation.DEFAULT);
        return;
      }
    },
    [currentAnimation, onWindowFloatChange]
  );

  const getContainerRef = useCallback((element: HTMLDivElement) => {
    containerRef.current = element;
  }, []);

  const visibleButtonPosition = mirrored
    ? {
        top: 2,
        right: 2,
      }
    : {
        top: 2,
        left: 2,
      };
  const minimizeButtonPosition = isFloatingWindow 
    ? {
      top: 10,
      left: 10,
      }
    : {
        top: 24,
        left: 24,
      };

  const visibleTooltip = isVisible ? "Hide view" : "Show view";
  const minimzeToolTip = isFloatingWindow ? "Maximize view" : "Floating viewer";
  const visibleButtonAltText = !isVisible ? title : undefined;

  return (
    <Container
      ref={getContainerRef}
      startMinimized={startMinimized}
      isFloatingWindow={isFloatingWindow}
      transitionEnabled={transitionEnabled}
      viewerSize={containerSize}
      currentAnimation={currentAnimation}
      onAnimationEnd={(event: any) => onContainerAnimationEnd(event.target)}
      style={{ ...containerSize }}
    >
      {!isVisible && (
        <FloatButton
          mirrored={mirrored}
          iconSrc={isVisibleIcon}
          onClick={onVisibleClick}
          tooltip={visibleTooltip}
          altText={visibleButtonAltText}
          {...visibleButtonPosition}
        />
      )}
      {canFloat && isVisible && (
        <FloatButton
          iconSrc={floatIcon}
          onClick={onFloatWindowClick}
          tooltip={minimzeToolTip}
          customStyle={!mirrored ? { transform: "rotateZ(180deg)" } : {}}
          {...minimizeButtonPosition}
        />
      )}
      {children}
    </Container>
  );
};

export default ViewerContainer;
