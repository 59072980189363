import styled, { css } from "styled-components";
import { ToolbarContainerStyleProps } from "./types";

const Container = styled.div`
  display: flex;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  background-color: rgba(32, 50, 74);
  flex-direction: row;
  border-radius: 6px;
  position: absolute;
  padding: 4px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  transition: background ease-in 0.2s;
  ${({ position }: ToolbarContainerStyleProps) =>
    position === "DOWN"
      ? css`
          bottom: 24px;
        `
      : css`
          top: 24px;
        `}

  &:hover {
    background-color: rgba(32, 50, 74, 1);
  }
  ${({ active }: ToolbarContainerStyleProps) =>
    active
      ? css`
          background-color: rgba(32, 50, 74, 1);
        `
      : ""};
`;

export { Container };
