export type SettingsState = {
  isVisible: boolean;
  selectedPhotoColor: string;
  vr2dPhotoColor: string;
  outlierPhotoColor: string;
  heightCalcGroupPhotoColor: string;
  informedTowerColor: string;
  estimatedTowerColor: string;
  shouldDisplayHeightCalcGroupPhoto: boolean;
  invertXMouseAxis: boolean;
  invertYMouseAxis: boolean;
  autoCenterCamera: boolean;
};

export type SettingsPayload = {
  isVisible?: boolean;
  selectedPhotoColor?: string;
  vr2dPhotoColor?: string;
  heightCalcGroupPhotoColor?: string;
  informedTowerColor?: string;
  estimatedTowerColor?: string;
  shouldDisplayHeightCalcGroupPhoto?: boolean;
  outlierPhotoColor?: string;
  invertXMouseAxis?: boolean;
  invertYMouseAxis?: boolean;
  autoCenterCamera?: boolean;
};

export enum SettingsActionType {
  SET_VISIBLE = "Settings@SET_VISIBLE",
  SET_SELECTED_PHOTO_COLOR = "Settings@SET_SELECTED_PHOTO_COLOR",
  SET_VR2D_PHOTO_COLOR = "Settings@SET_VR2D_PHOTO_COLOR",
  SET_OUTLIER_PHOTO_COLOR = "Settings@SET_OUTLIER_PHOTO_COLOR",
  SET_HEIGHT_CALC_GROUP_PHOTO_COLOR = "Settings@SET_HEIGHT_CALC_GROUP_PHOTO_COLOR",
  SET_INFORMED_TOWER_COLOR = "Settings@SET_INFORMED_TOWER_COLOR",
  SET_ESTIMATED_TOWER_COLOR = "Settings@SET_ESTIMATED_TOWER_COLOR",
  SET_INVERT_X_MOUSE_AXIS = "Settings@SET_INVERT_X_MOUSE_AXIS",
  SET_INVERT_Y_MOUSE_AXIS = "Settings@SET_INVERT_Y_MOUSE_AXIS",
  SET_SHOULD_DISPLAY_HEIGHT_CALC_GROUP_PHOTO = "Settings@SET_SHOULD_DISPLAY_HEIGHT_CALC_GROUP_PHOTO",
  SET_AUTO_CENTER_CAMERA = "Settings@SET_AUTO_CENTER_CAMERA",
  SAVE_SELECTED_PHOTO_COLOR = "Settings@SAVE_SELECTED_PHOTO_COLOR",
  SAVE_VR2D_PHOTO_COLOR = "Settings@SAVE_VR2D_PHOTO_COLOR",
  SAVE_OUTLIER_PHOTO_COLOR = "Settings@SAVE_OUTLIER_PHOTO_COLOR",
  SAVE_HEIGHT_CALC_GROUP_PHOTO_COLOR = "Settings@SAVE_HEIGHT_CALC_GROUP_PHOTO_COLOR",
  SAVE_INFORMED_TOWER_COLOR = "Settings@SAVE_INFORMED_TOWER_COLOR",
  SAVE_ESTIMATED_TOWER_COLOR = "Settings@SAVE_ESTIMATED_TOWER_COLOR",
  SAVE_INVERT_X_MOUSE_AXIS = "Settings@SAVE_INVERT_X_MOUSE_AXIS",
  SAVE_INVERT_Y_MOUSE_AXIS = "Settings@SAVE_INVERT_Y_MOUSE_AXIS",
  SAVE_SHOULD_DISPLAY_HEIGHT_CALC_GROUP_PHOTO = "Settings@SAVE_SHOULD_DISPLAY_HEIGHT_CALC_GROUP_PHOTO",
  SAVE_AUTO_CENTER_CAMERA = "Settings@SAVE_AUTO_CENTER_CAMERA",
  LOAD_SETTINGS = "Settings@LOAD_SETTINGS",
  CLEAR = "Settings@CLEAR",
}

export type SettingsAction = {
  type: SettingsActionType;
  payload?: SettingsPayload;
};

export type SettingsReducer = (
  state: SettingsState,
  payload?: SettingsPayload
) => SettingsState;
