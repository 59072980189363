import { put, takeLatest, call } from "redux-saga/effects";
import { ImagesMetaData } from "../../types";
import actions from "./actions";
import * as repository from "../../repository/imagesData";
import { ImagesDataAction, ImagesDataActionType } from "./types";

export function* fetchImagesData({ payload }: ImagesDataAction) {
  if (!payload) return;
  const { projectId } = payload;
  yield put(actions.setLoading(true));

  try {
    const imagesMetaData: ImagesMetaData = yield call(
      repository.getImagesData,
      projectId!
    );
    yield put(actions.setImagesMetaData(imagesMetaData));
  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setLoading(false));
  }
}

const sagas = [
  takeLatest(ImagesDataActionType.FETCH_IMAGES_DATA, fetchImagesData),
];

export default sagas;
