import { DirectionActions, PhotoData } from "../../../types";
import { UpdatePositionHandlers } from "./types";

const straightIndexPositionHandler: UpdatePositionHandlers = {
  isApplicable: (action: DirectionActions) => {
    return action === DirectionActions.STRAIGHT_INDEX;
  },
  apply: (
    _: PhotoData,
    __: number = 0,
    ___: number = 0,
    newIndex: number = 0
  ) => {
    return [newIndex];
  },
};

export default straightIndexPositionHandler;
