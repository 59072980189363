import { useCallback, useEffect, useRef, useState } from "react";
import { ViewersState, ViewersVisibleState } from "../types";

type SplitScreenViewerSizes = {
  photoViewer: { width: number; height: number };
  orbitViewer: { width: number; height: number };
};
const initialSplitScreenViewerSizes: SplitScreenViewerSizes = {
  photoViewer: { width: window.innerWidth / 2, height: window.innerHeight },
  orbitViewer: { width: window.innerWidth / 2, height: window.innerHeight },
};

const initialViewersVisibleState: ViewersVisibleState = {
  photoViewer: true,
  orbitViewer: true,
};

const useVisibleViews = () => {
  const [viewersVisibleState, setViewersVisibleState] = useState(
    initialViewersVisibleState
  );
  const transitionEnabledTimerRef = useRef<any>();
  const [viewersTransitionEnable, setViewersTransitionEnable] = useState(true);

  const [splitScreenViewerSizes, setSplitScreenViewerSizes] = useState(
    initialSplitScreenViewerSizes
  );
  const [isOrbitViewFloating, setFloatingOrbitView] = useState(false);

  const calcViewersSize = useCallback(() => {
    const {
      orbitViewer: isOrbitViewerVisible,
      photoViewer: isPhotoViewerVisible,
    } = viewersVisibleState;
    const minWidth = 0;
    const fullWidth = window.innerWidth;
    const orbitViewerFullWidth = isOrbitViewerVisible && !isPhotoViewerVisible;
    const orbitViewerWidth = orbitViewerFullWidth
      ? fullWidth
      : isOrbitViewerVisible
      ? window.innerWidth / 2
      : minWidth;
    const photoViewerFullWidth =
      (isPhotoViewerVisible && !isOrbitViewerVisible) || isOrbitViewFloating;
    const photoViewerWidth = photoViewerFullWidth
      ? fullWidth
      : isPhotoViewerVisible
      ? window.innerWidth / 2
      : minWidth;
    const newSplitScreenViewerSizes: SplitScreenViewerSizes = {
      orbitViewer: {
        width: orbitViewerWidth,
        height: window.innerHeight,
      },
      photoViewer: {
        width: photoViewerWidth,
        height: window.innerHeight,
      },
    };
    setSplitScreenViewerSizes(newSplitScreenViewerSizes);
  }, [isOrbitViewFloating, viewersVisibleState]);

  const onWindowResized = useCallback(() => {
    calcViewersSize();
  }, [calcViewersSize]);

  useEffect(() => {
    calcViewersSize();
  }, [calcViewersSize]);

  useEffect(() => {
    onWindowResized();
  }, [viewersVisibleState, onWindowResized]);

  useEffect(() => {
    window.addEventListener("resize", onWindowResized);
    return () => {
      window.removeEventListener("resize", onWindowResized);
    };
  }, [onWindowResized]);

  const onPhotoViewerVisibleChange = useCallback(
    (isVisible: boolean) => {
      let orbitViewer = viewersVisibleState.orbitViewer;
      if (!orbitViewer && !isVisible) orbitViewer = true;
      const newVisibleState: ViewersVisibleState = {
        photoViewer: isVisible,
        orbitViewer,
      };
      setFloatingOrbitView(false);
      setViewersVisibleState(newVisibleState);
    },
    [viewersVisibleState]
  );

  const onOrbitViewerVisibleChange = useCallback(
    (isVisible: boolean) => {
      let photoViewer = viewersVisibleState.photoViewer;
      if (!photoViewer && !isVisible) photoViewer = true;
      const newVisibleState: ViewersVisibleState = {
        orbitViewer: isVisible,
        photoViewer,
      };
      setFloatingOrbitView(false);
      setViewersVisibleState(newVisibleState);
    },
    [viewersVisibleState]
  );
  const onOrbitViewerFloatingChange = useCallback(
    (isFloatingWindow: boolean) => {
      const newVisibleState: ViewersVisibleState = {
        orbitViewer: true,
        photoViewer: true,
      };
      setViewersVisibleState(newVisibleState);
      setFloatingOrbitView(isFloatingWindow);
    },
    []
  );

  const transitionEnabledTimer = useCallback(() => {
    if (transitionEnabledTimerRef.current)
      clearTimeout(transitionEnabledTimerRef.current);
    transitionEnabledTimerRef.current = setTimeout(() => {
      setViewersTransitionEnable(true);
      transitionEnabledTimerRef.current = null;
    }, 200);
  }, []);

  useEffect(() => {
    if (!isOrbitViewFloating) {
      transitionEnabledTimer();
    }
    setViewersTransitionEnable(false);
  }, [isOrbitViewFloating, transitionEnabledTimer]);

  const onViewerVisibilityStateChange = (newVisibilityState: ViewersState) => {
    if (newVisibilityState === ViewersState.SIDE_BY_SIDE) {
      const newVisibleState: ViewersVisibleState = {
        photoViewer: true,
        orbitViewer: true,
      };
      setFloatingOrbitView(false);
      setViewersVisibleState(newVisibleState);
    }
    if (newVisibilityState === ViewersState.ONLY_ORBIT_VIEW) {
      const newVisibleState: ViewersVisibleState = {
        photoViewer: false,
        orbitViewer: true,
      };
      setFloatingOrbitView(false);
      setViewersVisibleState(newVisibleState);
    }
    if (newVisibilityState === ViewersState.ONLY_PHOTO_VIEW) {
      const newVisibleState: ViewersVisibleState = {
        photoViewer: true,
        orbitViewer: false,
      };
      setFloatingOrbitView(false);
      setViewersVisibleState(newVisibleState);
    }
    if (newVisibilityState === ViewersState.PICTURE_N_PICTURE) {
      const newVisibleState: ViewersVisibleState = {
        photoViewer: true,
        orbitViewer: true,
      };
      setFloatingOrbitView(true);
      setViewersVisibleState(newVisibleState);
    }
  };

  return {
    isOrbitViewFloating,
    viewersVisibleState,
    splitScreenViewerSizes,
    viewersTransitionEnable,
    onPhotoViewerVisibleChange,
    onOrbitViewerVisibleChange,
    onOrbitViewerFloatingChange,
    onViewerVisibilityStateChange,
  };
};

export default useVisibleViews;
