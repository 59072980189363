import styled, { keyframes } from "styled-components";

const Container = styled.div`
  position: absolute;
  z-index: 10;
  top: 24px;
  right: 24px;
  width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  animation: 0.2s forwards ${() => fadeInFromTop};
`;

const fadeInFromTop = keyframes`
0% {
    transform: translate(50%, -50%);
    opacity: 0;
}
100% {
    transform: translate(0, 0);
    opacity: 1;
}
`;

const OverlayText = styled.span`
  color: #243852;
  font-size: 11px;
`;

const OverlayTextBold = styled.span`
  color: #243852;
  font-size: 11px;
  font-weight: 700;
  padding-left: 4px;
`;

const ImageUrl = styled.span`
  color: #3367D6;
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 104px;
`;

const CurrentPhotoInfoTableTopSection = styled.div`
  padding-top: 20px;
  padding-bottom: 16px;
  display: grid;
  grid-row-gap: 10px;
  align-content: start;
  border-bottom: 1px solid #DFE3E8;
`;

const CurrentPhotoInfoTableBottomSection = styled.div`
  display: grid;
  grid-row-gap: 10px;
  align-content: start;
  position: relative;
  padding-top: 16px;
  padding-left: 60px;
`;

const CurrentPhotoInfoRow = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: auto auto;
  align-items: center;
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 6px;
  right: 12px;
`;


export { 
  Container, 
  OverlayText, 
  OverlayTextBold,
  ImageUrl,
  CurrentPhotoInfoTableTopSection, 
  CurrentPhotoInfoTableBottomSection,
  CurrentPhotoInfoRow, 
  CloseButtonContainer,
 };
