import { ApplicationState } from "../types";

const getSettingsState = (state: ApplicationState) => state.Settings;
const isVisible = (state: ApplicationState) =>
  getSettingsState(state).isVisible;
const getSelectedPhotoColor = (state: ApplicationState) =>
  getSettingsState(state).selectedPhotoColor;
const getVr2dPhotoColor = (state: ApplicationState) =>
  getSettingsState(state).vr2dPhotoColor;
const getOutlierPhotoColor = (state: ApplicationState) =>
  getSettingsState(state).outlierPhotoColor;
const getHeightCalcGroupPhotoColor = (state: ApplicationState) =>
  getSettingsState(state).heightCalcGroupPhotoColor;
const getInformedTowerColor = (state: ApplicationState) =>
  getSettingsState(state).informedTowerColor;
const getEstimatedTowerColor = (state: ApplicationState) =>
  getSettingsState(state).estimatedTowerColor;
const getInvertXMouseAxis = (state: ApplicationState) =>
  getSettingsState(state).invertXMouseAxis;
const getInvertYMouseAxis = (state: ApplicationState) =>
  getSettingsState(state).invertYMouseAxis;
const shouldDisplayHeightCalcGroupPhotos = (state: ApplicationState) =>
  getSettingsState(state).shouldDisplayHeightCalcGroupPhoto;
const getAutoCenterCamera = (state: ApplicationState) =>
  getSettingsState(state).autoCenterCamera;

const selectors = {
  isVisible,
  getSelectedPhotoColor,
  getVr2dPhotoColor,
  getOutlierPhotoColor,
  getHeightCalcGroupPhotoColor,
  getInformedTowerColor,
  getEstimatedTowerColor,
  getInvertXMouseAxis,
  getInvertYMouseAxis,
  shouldDisplayHeightCalcGroupPhotos,
  getAutoCenterCamera,
};

export default selectors;
