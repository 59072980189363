import { Store, createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./rootReducer";
import { ApplicationState } from "./types";
import rootSagas from "./rootSagas";

const sagaMiddleware = createSagaMiddleware();

const store: Store<ApplicationState> = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

export type ApplicationDispatch = typeof store.dispatch;

sagaMiddleware.run(rootSagas);

export default store;
