import { CompassProps } from "./types";
import { ArrowImg, CompassContainer, CompassImg, Container } from "./styles";
import arrowSrc from "../../assets/images/compass-arrow.svg";
import compassSrc from "../../assets/images/compass.svg";

const Compass = ({ yaw }: CompassProps) => {
  return (
    <Container>
      <CompassContainer>
        <CompassImg src={compassSrc} />
        <ArrowImg src={arrowSrc} yaw={yaw} />
      </CompassContainer>
    </Container>
  );
};

export default Compass;
