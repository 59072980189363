import { combineReducers } from "redux";
import CurrentPhotoDataReducer from "./CurrentPhotoData/reducer";
import ImagesDataReducer from "./ImagesData/reducer";
import SettingsReducer from "./Settings/reducer";

const reducers = combineReducers({
  CurrentPhotoData: CurrentPhotoDataReducer,
  ImagesData: ImagesDataReducer,
  Settings: SettingsReducer,
});

export default reducers;
export type ReducerState = ReturnType<typeof reducers>;
