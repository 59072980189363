import Button from "../Button";

import { OrbitalViewerControlPanelProps } from "./types";
import Icon from "../Icon";
import ToggleSwitch from "../ToggleSwitch";
import React, { useCallback, useEffect, useRef } from "react";
import { SubContainer } from "../ControlPanel/styles";
import ControlPanelSection from "../ControlPanelSection";
import DropdownMenu from "../DropdownMenu";

const OrbitalViewerControlPanel = ({
  isInVRPhotosVisible,
  isOutlierPhotosVisible,
  isInformedTowerVisible,
  isEstimatedTowerVisible,
  isVisible,
  towerMenuOpened,
  onInVRPhotosVisibleToggleChange,
  onOutlierPhotosVisibleToggleChange,
  onRecenterCameraClick,
  onInformedTowerVisibleToggleChange,
  onEstimatedTowerVisibleToggleChange,
  onTowerMenuChange,
}: OrbitalViewerControlPanelProps) => {
  const cesiumHelpButtonContainerRef = useRef<HTMLDivElement>();
  const getCesiumHelpButtonContainerRef = (el: HTMLDivElement) => {
    cesiumHelpButtonContainerRef.current = el;
  };
  const inVRPhotosTooltip = `${
    isInVRPhotosVisible ? "Hide" : "Show"
  } VR photos`;
  const outlierPhotosTooltip = `${
    isOutlierPhotosVisible ? "Hide" : "Show"
  } outlier photos`;
  const informedTowerToolTip = `${
    isInformedTowerVisible ? "Hide" : "Show"
  } informed tower`;
  const estimatedTowerToolTip = `${
    isEstimatedTowerVisible ? "Hide" : "Show"
  } estimated tower`;

  useEffect(() => {
    if (!cesiumHelpButtonContainerRef.current) return;
    const cesiumHelpButton = document.querySelector(".cesium-viewer-toolbar");
    if (!cesiumHelpButton) return;
    cesiumHelpButtonContainerRef.current.appendChild(cesiumHelpButton);
  }, [cesiumHelpButtonContainerRef]);

  const onTowerOptionsButtonClick = useCallback(() => {
    onTowerMenuChange(!towerMenuOpened);
  }, [onTowerMenuChange, towerMenuOpened]);

  const towerDisplayOptions = () => {
    return (
      <div style={{ position: "relative" }}>
        <DropdownMenu
          isVisible={towerMenuOpened}
          direction="FROM_BOTTOM"
          height={44}
        >
          <div style={{ padding: "4px 0" }}>
            <ToggleSwitch
              tooltip={informedTowerToolTip}
              alternative
              label={
                <span style={{ color: "#20324a" }}>
                  {"Display informed Tower"}
                </span>
              }
              isChecked={isInformedTowerVisible}
              onChange={onInformedTowerVisibleToggleChange}
            />
          </div>
          <div style={{ paddingBottom: "4px" }}>
            <ToggleSwitch
              tooltip={estimatedTowerToolTip}
              alternative
              label={
                <span style={{ color: "#20324a" }}>
                  {"Display estimated Tower"}
                </span>
              }
              isChecked={isEstimatedTowerVisible}
              onChange={onEstimatedTowerVisibleToggleChange}
            />
          </div>
        </DropdownMenu>
      </div>
    );
  };

  return (
    <ControlPanelSection
      isVisible={isVisible}
      maxWidth={100}
      overFlowHidden={!isVisible}
    >
      <SubContainer ref={getCesiumHelpButtonContainerRef}></SubContainer>
      <ToggleSwitch
        tooltip={inVRPhotosTooltip}
        label={<Icon iconSrc={"3d_rotation"} />}
        isChecked={isInVRPhotosVisible}
        onChange={onInVRPhotosVisibleToggleChange}
      />
      <ToggleSwitch
        tooltip={outlierPhotosTooltip}
        label={<Icon iconSrc={"blur_on"} />}
        isChecked={isOutlierPhotosVisible}
        onChange={onOutlierPhotosVisibleToggleChange}
      />
      <Button
        onClick={onTowerOptionsButtonClick}
        tooltip={"Tower display settings"}
        active={towerMenuOpened}
      >
        <Icon iconSrc={"cell_tower"} />
      </Button>
      <Button onClick={onRecenterCameraClick} tooltip={"Recenter camera"}>
        <Icon iconSrc={"gps_fixed"} />
      </Button>
      {towerDisplayOptions()}
      <SubContainer />
    </ControlPanelSection>
  );
};
export default OrbitalViewerControlPanel;
