import styled, { keyframes } from "styled-components";

import { ControlPanelSectionStyleProps } from "./types";

const Container = styled.div`
  display: flex;
  max-width: 0px;
  width: 0;
  overflow: ${({ overFlowHidden }: ControlPanelSectionStyleProps) =>
    overFlowHidden ? "hidden" : "initial"};
  animation: ${({ isVisible, maxWidth }: ControlPanelSectionStyleProps) =>
      isVisible ? slideIn(maxWidth) : slideInOut(maxWidth)}
    0.2s forwards;
`;
const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 5px;
  padding-right: 5px;
  border-right: solid 1px #667486;
`;

const slideIn = (maxWidth?: number) => keyframes`
0% {
    max-width: 0px;
    width: ${maxWidth ? maxWidth + "px" : "100%"};
}
100% {
    max-width: 100%;
    width: fit-content
}
`;
const slideInOut = (maxWidth?: number) => keyframes`
0% {
    max-width: 100%;
    width: ${maxWidth ? maxWidth + "px" : "100%"};
}
100% {
    max-width: 0px;
    width: 0;
}
`;
export { Container, SubContainer };
