import { Container, Progress } from "./styles";
import { LoadingBarProps } from "./types";

const LoadingBar = ({ progress }: LoadingBarProps) => {
  return (
    <Container backgroundColor={"white"}>
      {/* <span style={{ marginBottom: "5px" }}>{"Loading..."}</span> */}
      <Progress progressColor={"#7EC8E3"} progress={`${progress / 100}`}>
        {/* <span>{`${progress}%`}</span> */}
      </Progress>
    </Container>
  );
};

export default LoadingBar;
