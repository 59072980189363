import styled, { keyframes } from "styled-components";

import { ControlPanelStyleProps } from "./types";

const Container = styled.div`
  display: flex;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  background-color: #20324a;
  flex-direction: row;
  border-radius: 6px;
  padding: 4px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 99;
  opacity: 0;
  transform: translate(-50%, 0);
  animation: ${({ isVisible }: ControlPanelStyleProps) =>
      isVisible ? fadeInFromBottom : fadeOutToBottom}
    0.2s forwards;
`;
const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 5px;
  padding-right: 5px;
  border-right: solid 1px #667486;
`;

const fadeInFromBottom = keyframes`
0% {
    transform: translate(-50%, 200%);
    opacity: 0;
}
100% {
    transform: translate(-50%, 0);
    opacity: 1;
}
`;
const fadeOutToBottom = keyframes`
0% {
    transform: translate(-50%, 0);
    opacity: 1;
}
100% {
    transform: translate(-50%, 200%);
    opacity: 0;
}
`;
export { Container, SubContainer };
