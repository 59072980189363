import * as model from "./model";

const UNEXPECTED_ERROR =
  "An unexpected error has occurred, please try again later";

export const getImagesData = async (projectId: string) => {
  try {
    const response = await model.getImagesData(projectId);

    const { result, images_base_url, images_full_size_url } = response;
    let errorMessage = UNEXPECTED_ERROR;
    if (response.detail) {
      errorMessage = response.detail[0].msg;
    }
    if (!result || !images_base_url || !images_full_size_url) {
      throw new Error(errorMessage);
    }

    const { photos } = result;
    if (!photos) {
      throw new Error(errorMessage);
    }
    return response;
  } catch (e: any) {
    const errorMessage = e.message || UNEXPECTED_ERROR;
    throw new Error(errorMessage);
  }
};
