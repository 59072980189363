import { useState } from "react";
import { DirectionActions } from "../types";

const useKeyBoardControls = () => {
  const [currentChangeAction, setCurrentChangeAction] =
    useState<DirectionActions>();
  const onKeyDown = (event: KeyboardEvent) => {
    setCurrentChangeAction(undefined);
    const { shiftKey } = event;
    switch (event.key) {
      case "ArrowLeft":
        setCurrentChangeAction(DirectionActions.LEFT);
        break;
      case "ArrowRight":
        setCurrentChangeAction(DirectionActions.RIGHT);
        break;
      case "ArrowUp":
        if (shiftKey) setCurrentChangeAction(DirectionActions.FORWARD);
        else setCurrentChangeAction(DirectionActions.UP);
        break;
      case "ArrowDown":
        if (shiftKey) setCurrentChangeAction(DirectionActions.BACK);
        else setCurrentChangeAction(DirectionActions.DOWN);
        break;
      default:
        break;
    }
  };

  const onKeyUp = () => {
    setCurrentChangeAction(undefined);
  };

  return {
    onKeyDown,
    onKeyUp,
    currentChangeAction,
  };
};

export default useKeyBoardControls;
