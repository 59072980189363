import endpoints from "./endpoints";

const API_URL = process.env.REACT_APP_API_URL;
const API_TOKEN = process.env.REACT_APP_API_TOKEN;

const getImagesData = async (projectId: string) => {
  const headers = new Headers({
    "Content-Type": "Application/json",
    Authorization: `Bearer ${API_TOKEN}`,
  });

  const options: RequestInit = {
    method: "GET",
    mode: "cors",
    cache: "default",
    headers,
  };
  const url = `${API_URL}${endpoints.getImagesData}${projectId}`;
  const result = await fetch(url, options);
  return result.json();
};

export { getImagesData };
