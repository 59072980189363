import { ReactNode } from "react";

export type ViewerContainerProps = {
  children: ReactNode;
  viewerSize: { width: number; height: number };
  title: string;
  isVisible: boolean;
  mirrored?: boolean;
  canFloat?: boolean;
  isFloatingWindow?: boolean;
  transitionEnabled: boolean;
  startMinimized?: boolean;
  onVisibleChange: (isVisible: boolean) => void;
  onWindowFloatChange?: (isFloatingWindow: boolean) => void;
};

export type ContainerStyleProps = {
  isFloatingWindow?: boolean;
  transitionEnabled: boolean;
  startMinimized?: boolean;
  currentAnimation: ViewerAnimation | null;
  viewerSize: { width: number; height: number };
};

export enum ViewerAnimation {
  DEFAULT = "DEFAULT",
  ENTER_FLOAT_ANIMATION = "ENTER_FLOAT_ANIMATION",
  LEAVE_FLOAT_ANIMATION = "LEAVE_FLOAT_ANIMATION",
}
