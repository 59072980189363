import Button from "../Button";
import Icon from "../Icon";
import { AltText, Container } from "./styles";
import { FloatButtonProps } from "./types";

const FloatButton = ({
  iconSrc,
  left,
  top,
  bottom,
  right,
  tooltip,
  customStyle,
  altText,
  mirrored,
  onClick,
}: FloatButtonProps) => {
  return (
    <Container style={{ left, top, bottom, right, ...customStyle }}>
      <Button onClick={onClick} tooltip={tooltip} styles={{ padding: 6 }}>
        <>
          <Icon iconSrc={iconSrc} />
          {altText && <AltText mirrored={mirrored}>{altText}</AltText>}
        </>
      </Button>
    </Container>
  );
};

export default FloatButton;
