import { DirectionActions, PhotoData } from "../../../types";
import { UpdatePositionHandlers } from "./types";

const rightPositionHandlers: UpdatePositionHandlers = {
  isApplicable: (action: DirectionActions) => {
    return action === DirectionActions.RIGHT;
  },
  apply: (currentPhotoData: PhotoData) => {
    const nextPhotoIndex = currentPhotoData.right;
    if (nextPhotoIndex !== 0 && !nextPhotoIndex)
      return [currentPhotoData.index];
    return [nextPhotoIndex];
  },
};

export default rightPositionHandlers;
