import { ButtonProps } from "./types";
import { ButtonStyled } from "./styles";

const Button = ({
  onClick,
  children,
  tooltip,
  backgroundColor,
  foregroundColor,
  styles,
  active,
  noHoverEffect,
  getButtonRef,
}: ButtonProps) => {
  return (
    <ButtonStyled
      ref={getButtonRef}
      title={tooltip}
      onClick={onClick}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
      style={styles}
      active={active}
      noHoverEffect={noHoverEffect}
    >
      {children}
    </ButtonStyled>
  );
};

export default Button;
