import { useCallback, useEffect, useRef, useState } from "react";
import {
  DirectionActions,
  InteractionMode,
  UserInteraction,
  ZoomActions,
} from "../types";
import useKeyBoardControls from "./useKeyboardControls";
import useMouseControls from "./useMouseControls";

const useUserInteraction = (interactionMode: InteractionMode) => {
  const userScaleFunctionRef = useRef<Function>();
  const [canvasElement, setCanvasElement] = useState<HTMLCanvasElement>();
  const [currentChangeAction, setCurrentChangeAction] =
    useState<UserInteraction>();

  const {
    onMouseDown,
    onMouseLeave,
    onMouseMove,
    onMouseUp,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    onWheel,
    isMouseDown,
    currentChangeAction: mouseCurrentChangeAction,
  } = useMouseControls();
  const {
    onKeyDown,
    onKeyUp,
    currentChangeAction: keyboardCurrentChangeAction,
  } = useKeyBoardControls();

  useEffect(() => {
    setCurrentChangeAction(mouseCurrentChangeAction);
  }, [mouseCurrentChangeAction]);
  useEffect(() => {
    setCurrentChangeAction({
      directionAction: keyboardCurrentChangeAction,
    });
  }, [keyboardCurrentChangeAction]);
  const onDirectionButtonClick = useCallback(
    (directionAction: DirectionActions) => {
      setCurrentChangeAction({ directionAction });
    },
    []
  );
  const onZoomButtonClick = useCallback((zoomAction: ZoomActions) => {
    setCurrentChangeAction({ zoomAction });
  }, []);

  useEffect(() => {
    if (!canvasElement) return;
    canvasElement.addEventListener("mousedown", onMouseDown);
    canvasElement.addEventListener("mouseup", onMouseUp);
    canvasElement.addEventListener("mousemove", onMouseMove);
    canvasElement.addEventListener("mouseleave", onMouseLeave);
    canvasElement.addEventListener("wheel", onWheel);
    canvasElement.addEventListener("touchstart", onTouchStart);
    canvasElement.addEventListener("touchend", onTouchEnd);
    canvasElement.addEventListener("touchmove", onTouchMove);
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);

    return () => {
      canvasElement.removeEventListener("mousedown", onMouseDown);
      canvasElement.removeEventListener("mouseup", onMouseUp);
      canvasElement.removeEventListener("mousemove", onMouseMove);
      canvasElement.removeEventListener("mouseleave", onMouseLeave);
      canvasElement.removeEventListener("wheel", onWheel);
      canvasElement.removeEventListener("touchstart", onTouchStart);
      canvasElement.removeEventListener("touchend", onTouchEnd);
      canvasElement.removeEventListener("touchmove", onTouchMove);
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
    };
  }, [
    canvasElement,
    onKeyDown,
    onKeyUp,
    onMouseDown,
    onMouseLeave,
    onMouseMove,
    onMouseUp,
    onTouchEnd,
    onTouchMove,
    onTouchStart,
    onWheel,
  ]);

  useEffect(() => {
    if (!canvasElement) return;
    if (interactionMode === InteractionMode.DEFAULT) {
      canvasElement.style.cursor = "move";
    }
    if (interactionMode === InteractionMode.PAN) {
      canvasElement.style.cursor = isMouseDown ? "grabbing" : "grab";
    }
  }, [isMouseDown, canvasElement, interactionMode]);

  const getUserScaleRef = (getUserScale: Function) => {
    userScaleFunctionRef.current = getUserScale;
  };

  const userScale = userScaleFunctionRef.current
    ? userScaleFunctionRef.current()
    : 100;

  return {
    setCanvasElement,
    onDirectionButtonClick,
    onZoomButtonClick,
    getUserScaleRef,
    userScale,
    currentChangeAction,
  };
};

export default useUserInteraction;
