import { CanvasTranslateHandler } from "./types";

const normalizeTranslateHandler: CanvasTranslateHandler = {
  isApplicable: () => {
    return true;
  },
  apply: (currentPosition, canvasSize, imageSize, scale) => {
    const canvasWidth = canvasSize.width / scale;
    const imageWidth = imageSize.width;
    const canvasHeight = canvasSize.height / scale;
    const imageHeight = imageSize.height;
    let maxY = canvasHeight - imageHeight;
    if (canvasHeight < imageHeight) maxY = imageHeight - canvasHeight;
    let maxX = canvasWidth - imageWidth;
    if (canvasWidth < imageWidth) maxX = imageWidth - canvasWidth;

    let newX = currentPosition.x;
    let newY = currentPosition.y;

    if (newX > maxX / 2) {
      newX = maxX / 2;
    } else if (Math.abs(newX) > maxX / 2) {
      newX = (maxX / 2) * -1;
    }

    if (newY > maxY / 2) {
      newY = maxY / 2;
    } else if (Math.abs(newY) > maxY / 2) {
      newY = (maxY / 2) * -1;
    }

    return { x: newX, y: newY };
  },
};

export default normalizeTranslateHandler;
