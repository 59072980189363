import {
  CheckBox,
  Label,
  Container,
  Slider,
  LabelContent,
  CheckboxContainer,
} from "./styles";
import { ToggleSwitchProps } from "./types";

const ToggleSwitch = ({
  label,
  isChecked,
  alternative,
  tooltip,
  onChange,
}: ToggleSwitchProps) => {
  return (
    <Container>
      <Label title={tooltip}>
        <LabelContent>{label}</LabelContent>
        <CheckboxContainer>
          <CheckBox type={"checkbox"} checked={isChecked} onChange={onChange} />
          <Slider isChecked={isChecked} alternative={alternative} />
        </CheckboxContainer>
      </Label>
    </Container>
  );
};

export default ToggleSwitch;
