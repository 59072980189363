import { ImageData, PhotoData } from "../../types";
import { base64Decode, isValueDefined } from "../../utils";
import { ApplicationState } from "../types";

const getImagesDataState = (state: ApplicationState) => state.ImagesData;
const isLoading = (state: ApplicationState) =>
  getImagesDataState(state).loading;
const getInitialLoaded = (state: ApplicationState) =>
  getImagesDataState(state).initialLoaded;
const getError = (state: ApplicationState) => getImagesDataState(state).error;
const getData = (state: ApplicationState) => getImagesDataState(state).data;

const checkIfHasRelativeAltitudeUnset = (photos: PhotoData[]) =>
  !!photos.find(
    (p) => p.relative_altitude === null || p.relative_altitude === undefined
  );

const getPhotos = (state: ApplicationState) => {
  const data = getData(state);
  if (data) {
    let photos = data.result.photos;
    if (checkIfHasRelativeAltitudeUnset(photos)) {
      let minAltitude = Number.MAX_VALUE;
      photos.forEach((p) => {
        if (isValueDefined(p.normalized_absolute_altitude)) {
          minAltitude = Math.min(minAltitude, p.normalized_absolute_altitude!);
        } else {
          minAltitude = Math.min(minAltitude, p.absolute_altitude);
        }
      });
      photos = photos.map((photo) => ({
        ...photo,
        relative_altitude: isValueDefined(photo.normalized_absolute_altitude)
          ? photo.normalized_absolute_altitude! - minAltitude
          : photo.absolute_altitude - minAltitude,
      }));
    }
    return photos;
  }
  return [];
};

const getTowerData = (state: ApplicationState) => {
  const data = getData(state);
  if (data) return data.result.tower_data;
};

const getBasePath = (state: ApplicationState) => {
  const data = getData(state);

  if (data) {
    const basePath = data.images_base_url;
    if (basePath.charAt(basePath.length - 1) === "/") return basePath;
    return basePath + "/";
  }

  return "";
};

const getFullSizePath = (state: ApplicationState) => {
  const data = getData(state);
  if (data) {
    const fullSizePath = data.images_full_size_url;
    if (fullSizePath.charAt(fullSizePath.length - 1) === "/")
      return fullSizePath;
    return fullSizePath + "/";
  }
  return "";
};

const getPhotoByIndex = (
  state: ApplicationState,
  index: number
): PhotoData | undefined => {
  const photos = getPhotos(state);
  return photos.find((photo) => photo.index === index);
};

const getInitialPhotoIndex = (state: ApplicationState): number => {
  const params = new URLSearchParams(window.location.search);
  const firstImage = params.get("_fp");
  if (!firstImage) return 0;
  const photos = getPhotos(state);
  const firstPhotoPath = base64Decode(firstImage);
  const firstPhotoArgs = firstPhotoPath.split("/");
  const firstPhotoFilename = firstPhotoArgs[firstPhotoArgs.length - 1];
  const firstPhotoIndex = photos.find(
    ({ file }) => file === firstPhotoFilename
  )?.index;
  if (firstPhotoIndex) return firstPhotoIndex;
  return 0;
};

const getInitialImageData = (
  state: ApplicationState
): ImageData | undefined => {
  const initialPhotoIndex = getInitialPhotoIndex(state);

  const basePath = getBasePath(state);
  const initialPhotoData = getPhotoByIndex(state, initialPhotoIndex);
  if (!initialPhotoData) return;
  const imageFullPath = `${basePath}${initialPhotoData.file}`;
  return {
    id: initialPhotoData.index.toString(),
    path: imageFullPath,
  };
};

const getPhotosDownloadOrderList = (state: ApplicationState) => {
  const data = getData(state);
  if (data) {
    const { photos_download, photos } = data.result;
    if (photos_download) return photos_download;
    return photos.map(({ index }) => ({ index }));
  }

  return [];
};

const getQCReportUrl = (state: ApplicationState) => {
  const data = getData(state);
  if (data) {
    return data.qc_report_url;
  }
};

const selectors = {
  isLoading,
  getInitialLoaded,
  getError,
  getData,
  getPhotos,
  getBasePath,
  getFullSizePath,
  getInitialPhotoIndex,
  getPhotoByIndex,
  getInitialImageData,
  getPhotosDownloadOrderList,
  getQCReportUrl,
  getTowerData,
};

export default selectors;
