import { ImagesMetaData } from "../../types";

export type ImagesDataState = {
  data?: ImagesMetaData;
  loading: boolean;
  initialLoaded?: boolean;
  error?: Error;
};

export type ImagesDataPayload = {
  data?: ImagesMetaData;
  loading?: boolean;
  error?: Error;
  projectId?: string;
};

export enum ImagesDataActionType {
  SET_LOADING = "ImagesData@SET_LOADING",
  SET_DATA = "ImagesData@SET_DATA",
  SET_ERROR = "ImagesData@SET_ERROR",
  FETCH_IMAGES_DATA = "ImagesData@FETCH_IMAGES_DATA",
  CLEAR = "ImagesData@CLEAR",
}

export type ImagesDataAction = {
  type: ImagesDataActionType;
  payload?: ImagesDataPayload;
};

export type ImagesDataReducer = (
  state: ImagesDataState,
  payload?: ImagesDataPayload
) => ImagesDataState;
