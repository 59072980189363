import { PhotoData, SelectionMode, UserInteraction } from "../../types";

export type CurrentPhotoDataState = {
  currentPhoto?: PhotoData;
  selectionMode: SelectionMode;
  currentPhotoIndex: number;
};

export type CurrentPhotoDataPayload = {
  currentPhoto?: PhotoData;
  selectionMode?: SelectionMode;
  userInteraction?: UserInteraction;
  currentPhotoIndex?: number;
};

export enum CurrentPhotoDataActionType {
  SET_CURRENT_PHOTO_DATA = "CurrentPhotoData@SET_CURRENT_PHOTO_DATA",
  SET_SELECTION_MODE = "CurrentPhotoData@SET_SELECTION_MODE",
  UPDATE_CURRENT_PHOTO = "CurrentPhotoData@UPDATE_CURRENT_PHOTO",
  GET_INITIAL_PHOTO_DATA = "CurrentPhotoData@GET_INITIAL_PHOTO_DATA",
  CLEAR = "CurrentPhotoData@CLEAR",
}

export type CurrentPhotoDataAction = {
  type: CurrentPhotoDataActionType;
  payload?: CurrentPhotoDataPayload;
};

export type CurrentPhotoDataReducer = (
  state: CurrentPhotoDataState,
  payload?: CurrentPhotoDataPayload
) => CurrentPhotoDataState;
