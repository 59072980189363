import { DirectionActions, PhotoData } from "../../../types";
import { UpdatePositionHandlers } from "./types";

const customPositionHandlers: UpdatePositionHandlers = {
  isApplicable: (action: DirectionActions) => {
    return action === DirectionActions.CUSTOM;
  },
  apply: (_: PhotoData, deltaX: number = 0, deltaY: number = 0) => {
    return [deltaX, deltaY];
  },
};

export default customPositionHandlers;
