import { PhotoData, SelectionMode, UserInteraction } from "../../types";
import { CurrentPhotoDataAction, CurrentPhotoDataActionType } from "./types";

const actions = {
  setCurrentPhotoData: (currentPhoto: PhotoData): CurrentPhotoDataAction => ({
    payload: { currentPhoto },
    type: CurrentPhotoDataActionType.SET_CURRENT_PHOTO_DATA,
  }),
  setSelectionMode: (selectionMode: SelectionMode): CurrentPhotoDataAction => ({
    payload: { selectionMode },
    type: CurrentPhotoDataActionType.SET_SELECTION_MODE,
  }),
  updateCurrentPhoto: (
    userInteraction: UserInteraction
  ): CurrentPhotoDataAction => ({
    payload: { userInteraction },
    type: CurrentPhotoDataActionType.UPDATE_CURRENT_PHOTO,
  }),
  getInitialPhotoData: (
  ): CurrentPhotoDataAction => ({
    type: CurrentPhotoDataActionType.GET_INITIAL_PHOTO_DATA,
  }),
  clearState: (): CurrentPhotoDataAction => ({
    type: CurrentPhotoDataActionType.CLEAR,
  }),
};

export default actions;
