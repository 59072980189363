import PhotoViewerControlPanel from "../PhotoViewerControlPanel";
import { ToolbarProps } from "./types";
import OrbitalViewerControlPanel from "../OrbitalViewerControlPanel";
import ToolbarContainer from "../ToolbarContainer";
import { useState } from "react";

const Toolbar = ({
  interactionMode,
  viewersVisibleState,
  orbitPhotoVisibleState,
  towerDisplayVisibleState,
  isOrbitViewFloating,
  userScale,
  onRecenterCameraClick,
  onDirectionButtonClick,
  onZoomButtonClick,
  onInVRPhotosVisibleToggleChange,
  onOutlierPhotosVisibleToggleChange,
  onInformedTowerVisibleToggleChange,
  onEstimatedTowerVisibleToggleChange,
}: ToolbarProps) => {
  const [towerMenuOpened, setTowerMenuOpened] = useState(false);
  return (
    <ToolbarContainer position="DOWN" active={towerMenuOpened}>
      <OrbitalViewerControlPanel
        isVisible={viewersVisibleState.orbitViewer && !isOrbitViewFloating}
        onRecenterCameraClick={onRecenterCameraClick}
        isInVRPhotosVisible={orbitPhotoVisibleState.inVR}
        isOutlierPhotosVisible={orbitPhotoVisibleState.outlier}
        isInformedTowerVisible={towerDisplayVisibleState.informed}
        isEstimatedTowerVisible={towerDisplayVisibleState.estimated}
        onInVRPhotosVisibleToggleChange={onInVRPhotosVisibleToggleChange}
        onOutlierPhotosVisibleToggleChange={onOutlierPhotosVisibleToggleChange}
        onInformedTowerVisibleToggleChange={onInformedTowerVisibleToggleChange}
        onEstimatedTowerVisibleToggleChange={onEstimatedTowerVisibleToggleChange}
        towerMenuOpened={towerMenuOpened}
        onTowerMenuChange={setTowerMenuOpened}
      />
      <PhotoViewerControlPanel
        showZoomSection={viewersVisibleState.photoViewer}
        interactionMode={interactionMode}
        userScale={userScale}
        onDirectionButtonClick={onDirectionButtonClick}
        onZoomButtonClick={onZoomButtonClick}
      />
    </ToolbarContainer>
  );
};
export default Toolbar;
