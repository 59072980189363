import { useCallback, useRef, useState } from "react";
import { OrbitPhotoVisibleState, TowerDisplayVisibleState } from "../types";

const initialOrbitPhotoVisibleState: OrbitPhotoVisibleState = {
  inVR: true,
  outlier: true,
  inHeightCalcGroup: false,
};

const initialTowerDisplayVisibleState: TowerDisplayVisibleState = {
  estimated: true,
  informed: true,
};

const useOrbitViewerControls = () => {
  const [orbitPhotoVisibleState, setOrbitPhotoVisibleState] = useState(
    initialOrbitPhotoVisibleState
  );
  const [towerDisplayVisibleState, setTowerDisplayVisibleState] = useState(
    initialTowerDisplayVisibleState
  );
  const recenterCameraRef = useRef<Function>();
  const onInVRPhotosVisibleToggleChange = useCallback(() => {
    const newOrbitPhotoVisibleState = {
      ...orbitPhotoVisibleState,
      inVR: !orbitPhotoVisibleState.inVR,
    };
    setOrbitPhotoVisibleState(newOrbitPhotoVisibleState);
  }, [orbitPhotoVisibleState]);

  const onOutlierPhotosVisibleToggleChange = useCallback(() => {
    const newOrbitPhotoVisibleState = {
      ...orbitPhotoVisibleState,
      outlier: !orbitPhotoVisibleState.outlier,
    };
    setOrbitPhotoVisibleState(newOrbitPhotoVisibleState);
  }, [orbitPhotoVisibleState]);

  const onHeightCalcGroupVisibleToggleChange = useCallback(() => {
    const newOrbitPhotoVisibleState = {
      ...orbitPhotoVisibleState,
      inHeightCalcGroup: !orbitPhotoVisibleState.inHeightCalcGroup,
    };
    setOrbitPhotoVisibleState(newOrbitPhotoVisibleState);
  }, [orbitPhotoVisibleState]);

  const onInformedTowerVisibleToggleChange = useCallback(() => {
    const newTowerDisplayVisibleState = {
      ...towerDisplayVisibleState,
      informed: !towerDisplayVisibleState.informed,
    };
    setTowerDisplayVisibleState(newTowerDisplayVisibleState);
  }, [towerDisplayVisibleState]);

  const onEstimatedTowerVisibleToggleChange = useCallback(() => {
    const newTowerDisplayVisibleState = {
      ...towerDisplayVisibleState,
      estimated: !towerDisplayVisibleState.estimated,
    };
    setTowerDisplayVisibleState(newTowerDisplayVisibleState);
  }, [towerDisplayVisibleState]);

  const getRecenterCameraRef = (recenterCamera: Function) => {
    recenterCameraRef.current = recenterCamera;
  };

  const recenterCamera = () =>
    recenterCameraRef.current && recenterCameraRef.current();

  return {
    orbitPhotoVisibleState,
    towerDisplayVisibleState,
    recenterCamera,
    getRecenterCameraRef,
    onInVRPhotosVisibleToggleChange,
    onOutlierPhotosVisibleToggleChange,
    onHeightCalcGroupVisibleToggleChange,
    onInformedTowerVisibleToggleChange,
    onEstimatedTowerVisibleToggleChange,
  };
};

export default useOrbitViewerControls;
