import { useSelector } from "react-redux";
import useGeoLocationData from "../../hooks/useGeoLocationData";
import Compass from "../Compass";
import currentPhotoDataSelectors from "../../store/CurrentPhotoData/selectors";
import {
  Container,
  OverlayText,
  OverlayTextBold,
  CurrentPhotoInfoTableTopSection,
  CurrentPhotoInfoTableBottomSection,
  CurrentPhotoInfoRow,
  ImageUrl,
  CloseButtonContainer,
} from "./styles";
import Button from "../Button";
import Icon from "../Icon";
import useImagesManagment from "../../hooks/useImagesManagment";
import { useState } from "react";
import FloatButton from "../FloatButton";

const CurrentPhotoData = () => {
  const currentPhoto = useSelector(currentPhotoDataSelectors.getCurrentPhoto);
  const {
    currentYaw,
    currentLatitude,
    currentLongitude,
    currentAbsoluteAltitude,
    currentRelativeAltitude,
  } = useGeoLocationData(currentPhoto);

  const { openFullSizeImageInNewTab } = useImagesManagment();

  const [isOpenInfoTable, setIsOpenInfoTable] = useState(true);

  const currentPhotoName = currentPhoto ? currentPhoto.file : undefined;

  return (
    <>
      {isOpenInfoTable
        ? <Container>
          <CloseButtonContainer>
            <Button
              onClick={() => setIsOpenInfoTable(false)}
              tooltip={"Close info table"}
              foregroundColor={"#243852"}
              noHoverEffect
            >
              <Icon fontSize={20} iconSrc={"close"} />
            </Button>
          </CloseButtonContainer>
          <CurrentPhotoInfoTableTopSection>
            {currentLatitude && (
              <CurrentPhotoInfoRow>
                <OverlayText>{"Latitude:"}</OverlayText>
                <OverlayTextBold>{`${currentLatitude}°`}</OverlayTextBold>
              </CurrentPhotoInfoRow>
            )}
            {currentLongitude && (
              <CurrentPhotoInfoRow>
                <OverlayText>{"Longitude:"}</OverlayText>
                <OverlayTextBold>{`${currentLongitude}°`}</OverlayTextBold>
              </CurrentPhotoInfoRow>
            )}
            {currentAbsoluteAltitude && (
              <CurrentPhotoInfoRow>
                <OverlayText>{"Abs. Altitude:"}</OverlayText>
                <OverlayTextBold>{`${currentAbsoluteAltitude}m`}</OverlayTextBold>
              </CurrentPhotoInfoRow>
            )}
            {currentRelativeAltitude && (
              <CurrentPhotoInfoRow>
                <OverlayText>{"Rel. Altitude:"}</OverlayText>
                <OverlayTextBold>{`${currentRelativeAltitude}m`}</OverlayTextBold>
              </CurrentPhotoInfoRow>
            )}
          </CurrentPhotoInfoTableTopSection>
          <CurrentPhotoInfoTableBottomSection>
            {currentYaw && <Compass yaw={currentYaw} />}
            {currentYaw && (
                  <CurrentPhotoInfoRow>
                    <OverlayText>{"Gimbal Yaw:"}</OverlayText>
                    <OverlayTextBold>{`${currentYaw}°`}</OverlayTextBold>
                  </CurrentPhotoInfoRow>
                )}
            {currentPhotoName && (
              <CurrentPhotoInfoRow>
                <Button
                  onClick={() => openFullSizeImageInNewTab()}
                  tooltip={"Open real size photo in new tab"}
                  styles={{ paddingLeft: 0, paddingRight: 0 }}
                  noHoverEffect
                >
                  <>
                    <ImageUrl>{`${currentPhotoName}`}</ImageUrl>
                    <Icon iconSrc={"open_in_new"} color={"#3367D6"}/>
                  </>
                </Button>
              </CurrentPhotoInfoRow>
            )}
          </CurrentPhotoInfoTableBottomSection>
        </Container>
        : <FloatButton 
            iconSrc={"info"} 
            onClick={() => setIsOpenInfoTable(true)}
            tooltip={"Open info table"}
            top={24}
            right={24}
          />
      }
    </>
  );
};

export default CurrentPhotoData;
