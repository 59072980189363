import styled, { css, keyframes } from "styled-components";
import { ContainerStyleProps, ViewerAnimation } from "./types";

const Container = styled.div`
  position: ${({ isFloatingWindow }: ContainerStyleProps) =>
    isFloatingWindow ? "absolute" : "relative"};
  z-index: ${({ isFloatingWindow }: ContainerStyleProps) =>
    isFloatingWindow ? "10" : "auto"};
  box-shadow: ${({ isFloatingWindow }: ContainerStyleProps) =>
    isFloatingWindow ? " 4px 4px 4px rgba(0, 0, 0, 0.2)" : "none"};
  ${(props: ContainerStyleProps) => getAnimation(props)}
`;

const getAnimation = ({
  currentAnimation,
  viewerSize,
  transitionEnabled,
}: ContainerStyleProps) => {
  switch (currentAnimation) {
    case ViewerAnimation.ENTER_FLOAT_ANIMATION: {
      return css`
        animation: ${enterFloatWindowAnimation(viewerSize)} 0.25s forwards;
        bottom: 0;
        left: 0;
      `;
    }
    case ViewerAnimation.LEAVE_FLOAT_ANIMATION: {
      return css`
        animation: ${leaveFloatWindowAnimation(viewerSize)} 0.25s forwards;
        bottom: 0;
        z-index: 100;
      `;
    }
    default: {
      return transitionEnabled
        ? css`
            transition: width 0.25s ease-in-out;
            animation: none;
          `
        : "";
    }
  }
};

const enterFloatWindowAnimation = (viewerSize: {
  width: number;
  height: number;
}) => keyframes`
0% {
  width: ${viewerSize.width}px;
  height: ${viewerSize.height}px;
}
100% {
  width: 200px;
  height: 300px;
}
`;
const leaveFloatWindowAnimation = (viewerSize: {
  width: number;
  height: number;
}) => keyframes`
0% {
  width: 200px;
  height: 300px;
  }
100% {
  width: ${viewerSize.width}px;
  height: ${viewerSize.height}px;
}
`;

export { Container };
