import React, { ReactNode, useCallback, useRef, useState } from "react";
import { SelectionMode, ViewersState } from "../../types";

import Button from "../Button";

import Icon from "../Icon";

import { SubContainer } from "../ControlPanel/styles";
import { useDispatch, useSelector } from "react-redux";
import currentPhotoDataSelectors from "../../store/CurrentPhotoData/selectors";
import settingsSelectors from "../../store/Settings/selectors";
import imagesDataSelectors from "../../store/ImagesData/selectors";
import currentPhotoActions from "../../store/CurrentPhotoData/actions";
import settingsActions from "../../store/Settings/actions";
import DropdownMenu from "../DropdownMenu";
import { SelectViewOptionContainer } from "./styles";
import SideBySideIcon from "../SideBySideIcon";
import { ViewSettingsProps } from "./types";
import ToolbarContainer from "../ToolbarContainer";
import { exitFullScreen, isFullScreen, requestFullScreen } from "../../utils";

const ViewSettings = ({
  viewersVisibleState,
  isOrbitViewFloating,
  onViewerVisibilityStateChange,
}: ViewSettingsProps) => {
  const selectedMode = useSelector(currentPhotoDataSelectors.getSelectionMode);
  const QCReportUrl = useSelector(imagesDataSelectors.getQCReportUrl);
  const isSettingsMenuVisible = useSelector(settingsSelectors.isVisible);
  const dispatch = useDispatch();
  const inVRMode = selectedMode === SelectionMode.VR2D;
  const selectedModeIcon = inVRMode ? "lock" : "lock_open";
  const [viewSelectionOpen, setViewSelectionOpen] = useState(false);
  const menuButtonRef = useRef<ReactNode>();

  const setMenuButtonRef = (buttonRef: ReactNode) => {
    menuButtonRef.current = buttonRef;
  };

  const onModeSelectButtonClick = useCallback(() => {
    if (selectedMode === SelectionMode.FREE)
      return dispatch(currentPhotoActions.setSelectionMode(SelectionMode.VR2D));
    if (selectedMode === SelectionMode.VR2D)
      return dispatch(currentPhotoActions.setSelectionMode(SelectionMode.FREE));
  }, [dispatch, selectedMode]);

  const onOpenQCReportButtonClick = useCallback(() => {
    window.open(QCReportUrl, "_blank");
  }, [QCReportUrl]);

  const onSettingsButtonClick = useCallback(() => {
    dispatch(settingsActions.setVisible(!isSettingsMenuVisible));
  }, [dispatch, isSettingsMenuVisible]);

  const onSelectionOptionClick = (viewersState: ViewersState) => {
    onViewerVisibilityStateChange(viewersState);
    setViewSelectionOpen(false);
  };

  const onFullScreenClick = useCallback(async () => {
    const onFullScreen = isFullScreen();
    if (!onFullScreen) {
      try {
        await requestFullScreen(document.body);
      } catch (e) {}
    } else {
      try {
        await exitFullScreen();
      } catch (e) {}
    }
  }, []);

  const viewSelectOptions = () => {
    return (
      <div style={{ position: "relative" }}>
        <DropdownMenu
          isVisible={viewSelectionOpen}
          direction="FROM_TOP"
          height={100}
        >
          <SelectViewOptionContainer
            onClick={() => onSelectionOptionClick(ViewersState.SIDE_BY_SIDE)}
          >
            <span style={{ fontSize: "12px" }}>{"Side by Side"}</span>
            <div style={{ alignSelf: "flex-end" }}>
              <SideBySideIcon sideBySide />
            </div>
          </SelectViewOptionContainer>
          <SelectViewOptionContainer
            onClick={() => onSelectionOptionClick(ViewersState.ONLY_PHOTO_VIEW)}
          >
            <span style={{ fontSize: "12px" }}>{"Photo Viewer Only"}</span>
            <div style={{ alignSelf: "flex-end" }}>
              <SideBySideIcon onlyRightView sideBySide={false} />
            </div>
          </SelectViewOptionContainer>
          <SelectViewOptionContainer
            onClick={() => onSelectionOptionClick(ViewersState.ONLY_ORBIT_VIEW)}
          >
            <span style={{ fontSize: "12px" }}>{"Orbit Viewer Only"}</span>
            <div style={{ alignSelf: "flex-end" }}>
              <SideBySideIcon onlyLeftView sideBySide={false} />
            </div>
          </SelectViewOptionContainer>
          <SelectViewOptionContainer
            onClick={() =>
              onSelectionOptionClick(ViewersState.PICTURE_N_PICTURE)
            }
          >
            <span style={{ fontSize: "12px" }}>{"PnP"}</span>
            <div
              style={{ alignSelf: "flex-end", transform: "rotateZ(180deg)" }}
            >
              <Icon iconSrc="picture_in_picture" />
            </div>
          </SelectViewOptionContainer>
        </DropdownMenu>
      </div>
    );
  };

  const viewSelectIcon = () => {
    if (isOrbitViewFloating)
      return (
        <div style={{ transform: "rotateZ(180deg)" }}>
          <Icon iconSrc="picture_in_picture" />
        </div>
      );
    if (viewersVisibleState.orbitViewer && viewersVisibleState.photoViewer)
      return <SideBySideIcon sideBySide />;
    if (viewersVisibleState.orbitViewer)
      return <SideBySideIcon onlyLeftView sideBySide={false} />;
    if (viewersVisibleState.photoViewer)
      return <SideBySideIcon onlyRightView sideBySide={false} />;
  };

  const onViewMenuClick = useCallback(() => {
    setViewSelectionOpen(!viewSelectionOpen);
  }, [viewSelectionOpen]);

  const fullScreenIcon = isFullScreen() ? "fullscreen_exit" : "fullscreen";
  const tooltip = isFullScreen()
    ? "Exit full screen mode"
    : "Enter full screen mode";

  return (
    <ToolbarContainer
      position="UP"
      active={viewSelectionOpen || isSettingsMenuVisible}
    >
      <SubContainer>
        <Button onClick={onModeSelectButtonClick} tooltip={""}>
          <>
            <span
              style={{ fontSize: 10, whiteSpace: "nowrap", marginRight: 6 }}
            >
              {"VR2D"}
            </span>
            <Icon iconSrc={selectedModeIcon} />
          </>
        </Button>
      </SubContainer>
      <Button
        onClick={onSettingsButtonClick}
        tooltip={"Open setttings modal"}
        active={isSettingsMenuVisible}
      >
        <Icon iconSrc={"settings"} />
      </Button>
      <SubContainer>
        <Button
          getButtonRef={setMenuButtonRef}
          onClick={onViewMenuClick}
          tooltip={"Viewers layout"}
          active={viewSelectionOpen}
        >
          <>{viewSelectIcon()}</>
        </Button>
        <Button onClick={onFullScreenClick} tooltip={tooltip}>
          <Icon iconSrc={fullScreenIcon} />
        </Button>
        {viewSelectOptions()}
      </SubContainer>
      <Button
        onClick={onOpenQCReportButtonClick}
        tooltip={"Open Flight Analysis Report"}
      >
        <Icon iconSrc={"auto_graph"} />
      </Button>
    </ToolbarContainer>
  );
};
export default ViewSettings;
